<template>
  <v-dialog v-model="dialog" activator="parent" max-width="400">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />
      <v-card-text class="pb-0 pa-4">
        <h3 class="font-weight-regular text-center mb-4">Create Closure</h3>
        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12">
              <v-menu v-model="menu" :close-on-content-click="false" class="">
                <template v-slot:activator="{ props }">
                  <CustomTextFieldComponent
                    :rules="[requiredValidator]"
                    v-model="convertDate"
                    label="Date"
                    append-icon="event"
                    class="w-100"
                    clearable
                    prepend-inner-icon="mdi-calendar-month-outline"
                    v-bind="props"
                  />
                </template>
                <DatePicker
                  v-model="date"
                  borderless
                  mode="date"
                  @dayclick="menu = false"
                  class="bg-grey-darken-4 rounded-xl w-100"
                  title-position="left"
                  color="green"
                  is-dark="{ selector: ':root', darkClass: 'dark' }"
                />
              </v-menu>
            </v-col>
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="salary"
                type="text"
                inputmode="numeric"
                @input="$event.target.value = $event.target.value.slice(0, 10)"
                v-maska:[vMaskOptions]
                :maxlength="10"
                :rules="[requiredValidator, lengthOfSsalary]"
                label="Salary"
              />
            </v-col>
            <v-col cols="12">
              <CustomSelectComponent
                v-model="placementStatus"
                label="Placement status"
                :rules="[requiredValidator]"
                :items="['Joined', 'Withdrawn']"
              ></CustomSelectComponent>
            </v-col>
            <v-col cols="12">
              <CustomSelectComponent
                v-model="closureStatus"
                label="Closure status"
                :rules="[requiredValidator]"
                :items="['Confirmed', 'Withdrawn']"
              ></CustomSelectComponent>
            </v-col>
            <v-col cols="12">
              <CustomSelectComponent
                v-model="feeType"
                :items="['Fixed', 'Percentage']"
                :rules="[requiredValidator]"
                label="Fee type"
              />
            </v-col>
            <v-col cols="12" v-if="feeType === 'Percentage'">
              <CustomTextFieldComponent
                v-model.number="percentage"
                type="number"
                :rules="[requiredValidator]"
                label="Percentage"
                active="#B2D250"
              ></CustomTextFieldComponent>
            </v-col>
            <v-col cols="12" v-if="feeType === 'Fixed'">
              <CustomTextFieldComponent
                v-model.number="amount"
                type="number"
                :rules="[requiredValidator]"
                label="Amount"
                active="#B2D250"
              ></CustomTextFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
        <v-btn @click="dialog = false" variant="tonal" color="red"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onConfirm()"
    @update-model="confirmDialogStage = false"
    title="Create Closure"
    text="Are you sure you want to Create Closure?"
  />
</template>

<script setup>
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import { DatePicker } from "v-calendar";
import { computed, ref, watch } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { getDDMMYYYYFormat, getYYYYMMDDFormat } from "@/composables/DateTime";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { lengthOfSsalary } from "@/composables/Validators";
import { vMaska } from "maska";

const jobApplicationStore = useJobApplicationStore();
const confirmDialogStage = ref(false);
const menu = ref(false);
const dialog = ref(false);
const refForm = ref();
const date = ref(new Date());
const salary = ref(null);
const placementStatus = ref(null);
const closureStatus = ref(null);
const feeType = ref(null);
const percentage = ref(null);
const amount = ref(null);
const vMaskOptions = {
  mask: "99,99,99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};
const convertDate = computed(() => {
  return getDDMMYYYYFormat(date.value) === "Invalid date" ||
    getDDMMYYYYFormat(date.value) === "01/01/1970"
    ? ""
    : getDDMMYYYYFormat(date.value);
});

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const onConfirm = () => {
  let data = {
    date: getYYYYMMDDFormat(date.value),
    salary: Number(salary.value?.split(",").join("")),
    placementStatus: placementStatus.value,
    closureStatus: closureStatus.value,
    feeType: feeType.value,
    percentage: percentage.value ?? 0,
    amount: amount.value ?? 0,
  };
  confirmDialogStage.value = false;
  dialog.value = false;
  jobApplicationStore.createJobApplicationClosure(data);
};
watch(
  () => placementStatus.value,
  () => {
    if (placementStatus.value === "Joined") {
      closureStatus.value = "Confirmed";
    } else if (placementStatus.value === "Withdrawn") {
      closureStatus.value = "Withdrawn";
    }
  }
);
</script>
