export const corporateProfessions = [
  // IT Department
  "Software Developer",
  "Systems Administrator",
  "Network Engineer",
  "Database Administrator",
  "IT Support Specialist",
  "Cybersecurity Analyst",
  "Cloud Architect",
  "UI/UX Designer",
  "QA Engineer",
  "DevOps Engineer",

  // HR Department
  "Human Resources Manager",
  "Recruiter",
  "HR Coordinator",
  "Training and Development Specialist",
  "Compensation and Benefits Analyst",
  "Employee Relations Specialist",
  "HR Generalist",
  "Talent Acquisition Specialist",

  // Finance Department
  "Financial Analyst",
  "Accountant",
  "Controller",
  "Financial Planner",
  "Tax Specialist",
  "Auditor",
  "Budget Analyst",
  "Credit Analyst",
  "Investment Banker",
  "Risk Manager",

  // Accounts Department
  "Accounts Payable Clerk",
  "Accounts Receivable Clerk",
  "Bookkeeper",
  "Payroll Specialist",
  "Billing Specialist",
  "Financial Accountant",
  "Cost Accountant",
  "Management Accountant",
  "Tax Accountant",
  "Forensic Accountant",

  // Marketing Department
  "Marketing Manager",
  "Digital Marketing Specialist",
  "Content Writer",
  "Social Media Manager",
  "Marketing Analyst",
  "Brand Manager",
  "SEO Specialist",
  "Email Marketing Specialist",
  "Marketing Coordinator",
  "Public Relations Specialist",

  // Sales Department
  "Sales Manager",
  "Sales Representative",
  "Account Manager",
  "Business Development Manager",
  "Sales Support Specialist",
  "Sales Engineer",
  "Key Account Manager",
  "Inside Sales Representative",
  "Channel Sales Manager",
  "Retail Sales Associate",

  "Other",
  // Add more departments and professions as needed
];
