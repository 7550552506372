import "@mdi/font/css/materialdesignicons.css";
import "@mdi/light-js";
import "vuetify/styles";
import * as components from "vuetify/components";
// import * as directives from "vuetify/directives";
// import * as labsComponents from "vuetify/labs/components";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components: {
    ...components,
    // ...directives,
    // ...labsComponents,
  },
});
