<template>
  <v-dialog v-model="previousJobApplicationsStore.addUserDialog" max-width="600">
    <v-card title="Add User" theme="dark" color="#343435" heigth="300">
      <DialogCloseBtn
        @close-dialog="previousJobApplicationsStore.addUserDialog = false"
        class="mt-2"
      />

      <v-divider></v-divider>

      <PerfectScrollbar>
        <v-card-text class="mt-5">
          <!--  Form -->
          <v-form
            ref="refForm"
            v-model="isFormValid"
            @submit.prevent="confirmDialog = true"
          >
            <!--  Full name  row-->

            <v-row>
              <v-col cols="6" class="pt-0">
                <CustomTextFieldComponent
                  v-model="firstName"
                  @input="firstName = $event.target.value.replace(/\s+/g, ' ')"
                  :rules="[requiredValidator]"
                  label="First Name"
                />
              </v-col>

              <v-col cols="6" class="pt-0">
                <CustomTextFieldComponent
                  v-model="lastName"
                  @input="lastName = $event.target.value.replace(/\s+/g, ' ')"
                  :rules="[requiredValidator]"
                  label="Last Name"
                />
              </v-col>
            </v-row>
            <!-- 👉 EMail and number row -->
            <v-row>
              <!-- 👉 Email -->
              <v-col cols="6" class="pt-0">
                <CustomTextFieldComponent
                  v-model="email"
                  :rules="[requiredValidator, emailValidator]"
                  label="Email"
                />
              </v-col>

              <!-- 👉 number -->

              <v-col cols="6" class="pt-0">
                <CustomTextFieldComponent
                  v-model.number="number"
                  type="text"
                  @input="$event.target.value = $event.target.value.slice(0, 10)"
                  :maxlength="10"
                  :rules="[requiredValidator, mobileNumberValidator]"
                  label="Number"
                />
              </v-col>
            </v-row>
            <!-- GEnder & Blood Group Row -->
            <v-row>
              <v-col cols="6" class="pt-0">
                <CustomSelectComponent
                  v-model="gender"
                  label="Gender"
                  :rules="[requiredValidator]"
                  :items="['Male', 'Female']"
                ></CustomSelectComponent>
              </v-col>

              <!-- 👉 Blood Group -->
              <v-col cols="6" class="pt-0">
                <CustomSelectComponent
                  v-model="bloodGroup"
                  label="Blood Group"
                  :rules="[requiredValidator]"
                  :items="['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']"
                />
              </v-col>
            </v-row>
            <!-- Department & Designation Row -->
            <v-row>
              <!-- department -->
              <v-col cols="6" class="pt-0">
                <CustomSelectComponent
                  v-model="department"
                  label="Department"
                  :rules="[requiredValidator]"
                  :items="departments?.map((dpt) => dpt.name)"
                />
              </v-col>

              <!-- designation -->
              <v-col cols="6" class="pt-0">
                <CustomSelectComponent
                  v-model="designation"
                  label="Designation"
                  :rules="[requiredValidator]"
                  :items="designations?.map((dsg) => dsg.name)"
                />
              </v-col>
            </v-row>
            <!-- Role & Leverage Row -->
            <v-row>
              <v-col cols="6" class="pt-0">
                <CustomSelectComponent
                  v-model="role"
                  :rules="[requiredValidator]"
                  :items="rolesWithoutAdmin"
                  label="Role"
                />
              </v-col>
            </v-row>
            <!-- Joining & Birth Date Row -->
            <v-row>
              <v-col cols="12" sm="6" class="pt-0">
                <v-menu v-model="menu" :close-on-content-click="false" class="">
                  <template v-slot:activator="{ props }">
                    <CustomTextFieldComponent
                      v-model="convertSelectedJoiningDate"
                      label="Joining Date"
                      append-icon="event"
                      @click:clear="selectedJoiningDate = null"
                      class="w-100"
                      readonly
                      prepend-inner-icon="mdi-calendar-month-outline"
                      v-bind="props"
                    />
                  </template>
                  <DatePicker
                    v-model="selectedJoiningDate"
                    borderless
                    mode="date"
                    @dayclick="menu = false"
                    class="bg-grey-darken-4 rounded-xl w-100"
                    title-position="left"
                    color="green"
                    is-dark="{ selector: ':root', darkClass: 'dark' }"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" class="pt-0">
                <v-menu v-model="menu2" :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <CustomTextFieldComponent
                      v-model="convertSelectedBirthDate"
                      label="Birth Date"
                      append-icon="event"
                      @click:clear="candidateStore.acknowledgement = null"
                      readonly
                      prepend-inner-icon="mdi-calendar-month-outline"
                      v-bind="props"
                    />
                  </template>
                  <DatePicker
                    v-model="selectedBirthDate"
                    borderless
                    mode.string="date"
                    @dayclick="menu2 = false"
                    class="bg-grey-darken-4 rounded-xl mb-3"
                    title-position="left"
                    :max-date="new Date()"
                    color="green"
                    is-dark="{ selector: ':root', darkClass: 'dark' }"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0">
                <CustomTextFieldComponent
                  v-model="userName"
                  :clearable="false"
                  :disabled="checkDetailsValue"
                  autofocus
                  :rules="[requiredValidator]"
                  label="Username"
                  append-inner-icon="mdi-check-circle"
                  @click:appendInner="checkUsername()"
                >
                </CustomTextFieldComponent>
              </v-col>
            </v-row>

            <v-row>
              <!-- 👉 Submit and Cancel -->

              <v-col cols="12">
                <v-btn type="submit" class="mx-auto" block color="#74A942" elevation="7">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </PerfectScrollbar>
    </v-card>
  </v-dialog>

  <ConfirmationDialog
    v-model="confirmDialog"
    @action-yes="onSubmit()"
    @update-model="confirmDialog = false"
    title="Add User"
    text="'Are you sure you want to add this User?'"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import ConfirmationDialog from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { requiredValidator } from "@/composables/Validators";
import { emailValidator } from "@/composables/Validators";
import { mobileNumberValidator } from "@/composables/Validators";
import { computed, inject, ref, watch } from "vue";
import { DatePicker } from "v-calendar";
import { getMonthDateYearFormat } from "@/composables/DateTime";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { getDataFromLocalStorage } from "@/composables/LocalStorage";
import { getDepartments, getDesignations, getRoleName } from "@/composables/LocalStorage";
import { usePreviousJobApplicationsStore } from "@/store/AddPreviousJobApplications";
const previousJobApplicationsStore = usePreviousJobApplicationsStore();
const $cookies = inject("$cookies");
const user = $cookies.get("user");
const confirmDialog = ref(false);
const isFormValid = ref(false);
const refForm = ref();
const firstName = ref("");
const lastName = ref("");
const email = ref("");
const number = ref("");
const userName = ref("");
const designation = ref("");
const department = ref("");
const bloodGroup = ref("");
const gender = ref("");
const role = ref("");
// const OfficialMailId = ref("");
const selectedBirthDate = ref();
const selectedJoiningDate = ref();
const menu = ref(false);
const menu2 = ref(false);

const checkDetailsValue = computed(() => {
  if (firstName.value && lastName.value && selectedBirthDate.value) {
    return false;
  } else {
    return true;
  }
});

const roles = computed(() => {
  const roles = getDataFromLocalStorage("rolesName");
  return roles;
});
const departments = computed(() => {
  const department = getDataFromLocalStorage("departments");
  return department;
});
const designations = computed(() => {
  const designations = getDataFromLocalStorage("designations");
  return designations;
});
const rolesWithoutAdmin = computed(() => {
  if (user.role === "ADMIN") {
    return roles.value;
  } else {
    return roles.value?.filter((role) => role !== "ADMIN");
  }
});

const convertSelectedBirthDate = computed(() => {
  return getMonthDateYearFormat(selectedBirthDate.value) === "Invalid date"
    ? ""
    : getMonthDateYearFormat(selectedBirthDate.value);
});
const convertSelectedJoiningDate = computed(() => {
  return getMonthDateYearFormat(selectedJoiningDate.value) === "Invalid date"
    ? ""
    : getMonthDateYearFormat(selectedJoiningDate.value);
});

const onSubmit = async () => {
  if (isFormValid.value) {
    const userData = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      number: number.value,
      gender: gender.value,
      bloodGroup: bloodGroup.value,
      role: role.value,
      designation: designation.value,
      department: department.value,
      dateOfJoining: selectedJoiningDate.value.toISOString(),
      dateOfBirth: selectedBirthDate.value.toISOString(),
      userName: userName.value,
    };
    confirmDialog.value = false;
    await previousJobApplicationsStore.addUser(userData);
    previousJobApplicationsStore.addUserDialog = false;
  } else {
    console.log("Form is invalid");
  }
};
watch(
  () => [selectedBirthDate.value],
  async () => {
    if (firstName.value && lastName.value && selectedBirthDate.value) {
      const usernameData = `${firstName.value.toLocaleLowerCase()}_${lastName.value.toLocaleLowerCase()}_${selectedBirthDate.value
        ?.toISOString()
        ?.split("-")[0]
        .slice(-2)}`;
      userName.value = usernameData;
    }
  }
);
watch(
  () => previousJobApplicationsStore.addUserDialog,
  async () => {
    if (previousJobApplicationsStore.addUserDialog === true) {
      if (roles.value === null) {
        await getRoleName();
      } else if (departments.value === null) {
        await getDepartments();
      } else if (designations.value === null) {
        await getDesignations();
      }
    }
  }
);
</script>
