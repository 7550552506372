<template>
  <v-autocomplete
    variant="outlined"
    rounded="lg"
    theme="dark"
    clearable
    :density="density"
    :color="color"
    :disabled="disabled"
    :label="label"
  />
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["type", "label", "value", "disabled", "density"]);
const density = props.density ? props.density : "compact";
const label = props.label ? props.label : "";
const disabled = props.disabled ? props.disabled : false;
const color = props.color ? props.color : "#b2d250";
</script>