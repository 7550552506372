<template>
  <v-app-bar scroll-behavior="elevate" color="#373737" class="pl-3" elevation="6">
    <v-app-bar-title>{{ $route.name }}</v-app-bar-title>

    <v-btn variant="text" v-if="user.role === 'SUPER_USER'">
      Add Job application <AddPreviousJobApplications />
    </v-btn>
    <v-btn icon variant="text" @click="dashboardStore.handleSearchDialog(true)">
      <v-icon>mdi-magnify</v-icon>
      <v-tooltip activator="parent">Search</v-tooltip>
    </v-btn>
  </v-app-bar>
</template>

<script setup>
import { useDashboardStore } from "@/store/DashboardStore/Dashboard";
import AddPreviousJobApplications from "../SharedComponent/PreviousJobApplications/AddPreviousJobApplications.vue";
import { inject } from "vue";
const $cookies = inject("$cookies");
const user = $cookies.get("user");
const dashboardStore = useDashboardStore();
</script>
