import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import VueCookies from "vue-cookies";
// import { toast } from "vue3-toastify";

export const useDashboardStore = defineStore("dashboardStore", {
  state: () => ({
    userId: VueCookies.get("user")?.id,
    dashboard: {},
    attendanceData: {},
    dailyAllocationsData: [],
    isLoading: false,
    isLoaded: false,
    punchInDialog: false,
    punchOutDialog: false,
    noDataFoundDialog: false,
    driveJobsDialog: false,
    searchDialog: false,
    ongoingDrives: [],
    searchedCandidate: {},
    searchOngoingDrive: "",
  }),
  actions: {
    async getDashboard() {
      this.isLoading = true;
      await api
        .get(`/dashboard/recruiter/${this.userId}`)
        .then((res) => {
          this.dashboard = res.data;
          this.attendanceData = res.data.attendance;
          this.dailyAllocationsData = res.data.dailyJobAllocations;
          this.isLoading = false;
          this.isLoaded = true;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async punchIn(id) {
      this.isLoading = true;
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          this.punchInDialog = false;
          await api
            .post("/attendance/punch-in", {
              userId: id,
              location: {
                latitude: latitude,
                longitude: longitude,
              },
            })
            .then((res) => {
              this.attendanceData = res.data;
              toast.success("Successfully Punched In", {
                autoClose: 1500,
                theme: "dark",
                position: toast.POSITION.TOP_CENTER,
                transition: toast.TRANSITIONS.ZOOM,
              });
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              return error;
            });
        });
      } else {
        toast.error("Geolocation is not available", {
          autoClose: 1500,
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          transition: toast.TRANSITIONS.ZOOM,
        });
        this.isLoading = false;
      }
    },
    async punchOut(id) {
      this.isLoading = true;

      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.punchOutDialog = false;
            await api
              .post("/attendance/punch-out", {
                userId: id,
                location: {
                  latitude: latitude,
                  longitude: longitude,
                },
              })
              .then((res) => {
                this.attendanceData = res.data;
                toast.success("Successfully Punched Out", {
                  autoClose: 1500,
                  theme: "dark",
                  position: toast.POSITION.TOP_CENTER,
                  transition: toast.TRANSITIONS.ZOOM,
                });

                this.isLoading = false;
              })
              .catch((error) => {
                this.isLoading = false;
                return error;
              });
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        alert("Geolocation is not available");
        this.isLoading = false;

      }
    },
    async dailyAllocationJob(data) {
      this.isLoading = true;
      await api
        .post(`/daily-job-allocation/allocate`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getOngoingDrives() {
      this.isLoading = true;
      await api
        .get(`/jobs/ongoing-drives`)
        .then((res) => {
          this.ongoingDrives = res.data;
          this.isLoading = false;
          this.isLoaded = true;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    handleResumePendingCard() {
      if (
        this.dashboard.jobApplicationApprovals.count == 0 &&
        this.dashboard.jobApplicationTeamApprovals.count === 0
      ) {
        this.noDataFoundDialog = true;
      } else {
        this.router.push({ name: "Approvals Pending" });
      }
    },
    handleAttendancePendingCard() {
      if (this.dashboard.attendanceApprovals.count === 0) {
        this.noDataFoundDialog = true;
      } else {
        this.router.push("/attendance-approval-pending");
      }
    },
    async handleDriveJobsCard() {
      if (this.dashboard.onGoingDrives.count === 0) {
        this.noDataFoundDialog = true;
      } else {
        await this.getOngoingDrives();
      }
      this.driveJobsDialog = true;
    },
    handleSearchDialog(value) {
      this.searchDialog = value;
    },
  },
  getters: {
    attendance() {
      return this.attendanceData;
    },
    dailyAllocations() {
      return this.dailyAllocationsData;
    },
    getFormattedOngoingDrives() {
      let search = this.searchOngoingDrive.toLocaleLowerCase();
      if (search) {
        return this.ongoingDrives?.filter(
          (drive) =>
            drive.title.toLocaleLowerCase().includes(search) ||
            drive.clientName.toLocaleLowerCase().includes(search)
        );
      } else {
        return this.ongoingDrives;
      }
    },
  },
});
