import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { useJobApplicationsStore } from "./JobApplicationsStore";

export const useJobApplicationApprovalPendingStore = defineStore(
  "jobApprovalPendingStore",
  {
    state: () => ({
      jobApplicationsStore: useJobApplicationsStore(),
      isLoading: false,
      seniorApprovalPending: [],
      teamApprovalPending: [],
      joiningStatus: null,
      recruiters: null,
      search: null,
      tabs: 0,
    }),
    actions: {
      async getJobApplicationsApprovalPending(seniorId) {
        (this.isLoading = true),
          await api
            .get(`/job-applications/pending-approvals/senior/${seniorId}`)
            .then((res) => {
              this.seniorApprovalPending = res.data.seniorApprovalPending;
              this.teamApprovalPending = res.data.teamApprovalPending;
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              return err;
            });
      },
      async approveJobApplication(jobApplicationId, comment) {
        (this.isLoading = true),
          await api
            .put(`/job-applications/${jobApplicationId}/approve`, comment)
            .then((res) => {
              if (res.status === 200) {
                const dataToUse =
                  this.tabs === 0
                    ? this.seniorApprovalPending
                    : this.teamApprovalPending;
                const jobApplicationIndex = dataToUse.findIndex(
                  (jobApplication) => jobApplication.id === jobApplicationId
                );
                if (jobApplicationIndex !== -1) {
                  dataToUse.splice(jobApplicationIndex, 1);
                }
                toast.success(res.data, {
                  autoClose: 1500,
                  theme: "dark",
                  position: toast.POSITION.TOP_CENTER,
                  transition: toast.TRANSITIONS.ZOOM,
                });
              }
              this.isLoading = false;
              this.jobApplicationsStore.overlay = false;
            })
            .catch((err) => {
              this.jobApplicationsStore.overlay = false;
              this.isLoading = false;
              return err;
            });
      },
      async rejectJobApplication(jobApplicationId, data) {
        (this.isLoading = true),
          await api
            .put(`/job-applications/${jobApplicationId}/reject`, data)
            .then((res) => {
              if (res.status === 200) {
                if (res.status === 200) {
                  const dataToUse =
                    this.tabs === 0
                      ? this.seniorApprovalPending
                      : this.teamApprovalPending;
                  const jobApplicationIndex = dataToUse.findIndex(
                    (jobApplication) => jobApplication.id === jobApplicationId
                  );
                  if (jobApplicationIndex !== -1) {
                    dataToUse.splice(jobApplicationIndex, 1);
                  }
                }
                toast.success(res.data, {
                  autoClose: 1500,
                  theme: "dark",
                  position: toast.POSITION.TOP_CENTER,
                  transition: toast.TRANSITIONS.ZOOM,
                });
              }
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              return err;
            });
      },
      refreshApprovalPending(seniorId) {
        this.getJobApplicationsApprovalPending(seniorId);
      },
    },
    getters: {
      getApprovalPendingFormattedJobApplication() {
        const dataToUse =
          this.tabs === 0
            ? this.seniorApprovalPending
            : this.teamApprovalPending;
        const lowerCaseSearch = this.search ? this.search.toLowerCase() : "";
        const lowerCaseJoiningStatus = this.joiningStatus
          ? this.joiningStatus.toLowerCase()
          : "";
        const lowerCaseRecruiter = this.recruiters
          ? this.recruiters.toLowerCase()
          : "";

        return dataToUse.filter((application) => {
          const lowerCaseJobTitle = application.jobTitle.toLowerCase();
          const lowerCaseClientName = application.clientName.toLowerCase();
          const lowerCaseRecruiterName =
            application.recruiterName.toLowerCase();
          const lowerCaseNoticePeriodStatus = application.noticePeriodStatus
            .toLowerCase()
            .replace("_", " ");

          return (
            (!this.recruiters ||
              lowerCaseRecruiterName === lowerCaseRecruiter) &&
            (!this.joiningStatus ||
              lowerCaseNoticePeriodStatus === lowerCaseJoiningStatus) &&
            (!this.search ||
              lowerCaseJobTitle.includes(lowerCaseSearch) ||
              lowerCaseClientName.includes(lowerCaseSearch))
          );
        });
      },
      getRecruitersName() {
        const set = new Set();
        (this.tabs === 0
          ? this.seniorApprovalPending
          : this.teamApprovalPending
        ).forEach((application) => set.add(application.recruiterName));
        return [...set];
      },
    },
  }
);
