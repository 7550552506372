import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import "resize-observer-polyfill";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "@mdi/light-js";
import VCalendar from "v-calendar";
import VDatePicker from "v-calendar";
import "v-calendar/style.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueCookies from "vue-cookies";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import "primevue/resources/themes/lara-light-teal/theme.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/primevue.css";
import { usePermissionDirective } from "./composables/Directives";
import "primeicons/primeicons.css";
import VueApexCharts from "vue3-apexcharts";

loadFonts();
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(VueApexCharts)
  .use(VDatePicker, {})
  .use(VCalendar, {})
  .use(VueCookies, { expires: "7d" })
  .use(Vue3Toastify, {
    autoClose: 4000,
  })
  .use(PrimeVue)
  .use(ConfirmationService)
  .directive("permission", usePermissionDirective)
  .mount("#app");

// // const authStore = useAuthStore();
// router.beforeEach((to, from, next) => {
//   if (to.name !== "Login" && !VueCookies.get("token")) {
//     next({ name: "Login" });
//   } else if (to.name === "Login" && VueCookies.get("token")) {
//     next({ name: "Dashboard" });
//   } else {
//     const requiredPermission = to.meta.permission;

//     if (requiredPermission) {
//       const userPermissions = VueCookies.get("user").permissions;

//       if (!checkPermission(userPermissions, requiredPermission)) {
//         toast.error("not Permited");
//         next({ name: "Unauthorized" });
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   }
// });// Router navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = VueCookies.get("token");
  if (!isAuthenticated) {
    VueCookies.remove("token");
    VueCookies.remove("user");
    VueCookies.remove("refresh_token");
    localStorage.removeItem("authStore");
    handleUnauthenticatedRoute(to, next);
  } else if (to.name === "Login") {
    handleLoginRoute(next);
  } else {
    handlePermissionCheck(to, next);
  }
});

function handleUnauthenticatedRoute(to, next) {
  if (to.name !== "Login") {
    next({ name: "Login" });
  } else {
    next();
  }
}

function handleLoginRoute(next) {
  next({ name: "Dashboard" });
}
function handlePermissionCheck(to, next) {
  const requiredPermission = to.meta.permission;
  const isSuperAdmin =
    VueCookies.get("user").role === "SUPER_ADMIN" ||
    VueCookies.get("user").role === "SUPER_USER";
  if (requiredPermission && !isSuperAdmin) {
    const userPermissions = VueCookies.get("user").permissions;
    if (!checkPermission(userPermissions, requiredPermission)) {
      next({ name: "Unauthorized" });
    } else {
      next();
    }
  } else {
    next();
  }
}
function checkPermission(userPermissions, requiredPermission) {
  // Implement your logic to check if the user has the required permissions
  let checkPermission = userPermissions.includes(requiredPermission);
  return checkPermission;
}
