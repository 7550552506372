export const confirmedValidator = (value, target) =>
  value === target || "The Confirm Password field confirmation does not match";

export const INRAmmount = (value) => {
  return value.toLocaleString("en-IN");
};
export const requiredValidator = (value) => !!value || "Required.";

export const lengthOfWordValidator = (value) =>
  value.length >= 3 || "At least 3 letters required";
export const commentLengthValidator = (value) =>
  value.length >= 30 || "At least 30 letters required";
export const lengthOfSsalary = (value) => {
  const pattern = /^[0-9]{5,}$/;
  const sanitizedValue = value.replace(/,/g, "");
  return pattern.test(sanitizedValue) || "Atleast 5 digit required.";
};
export const validateString = (value) => {
  const pattern = /^[A-Za-z]( ?[A-Za-z] ?)*$/g;
  const cleanedString = value.replace(/\s+/g, " ");

  return (
    pattern.test(cleanedString) || "Only alphanumeric characters are allowed."
  );
};

export const noSpaceValidator = (value) => {
  const spacedVlaue = value.split(" ");
  return spacedVlaue.length == 1 || "Space Not Allowed";
};
export const mobileNumberValidator = (value) => {
  const pattern = /^[0-9]{10}$/;
  return pattern.test(value) || "Invalid mobile number.";
};
export const nonNegativeNumberValidator = (value) => {
  const isNonNegative = /^\d+$/.test(value) && parseInt(value, 10) >= 0;
  return isNonNegative || "Please enter a non-negative number.";
};

export const emailValidator = (value) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(value) || "Invalid e-mail.";
};
export const passwordValidator = (password) => {
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
  const validPassword = regExp.test(password);

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    "Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars"
  );
};
