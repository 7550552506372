<script setup>
import { computed, ref, watch } from "vue";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import LoaderDialog from "@/components/SharedComponent/dialogs/LoaderDialog.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import AcceptOfferDialog from "@/components/JobAplication/dialogs/AcceptOfferDialog.vue";
import RescheduleDialog from "@/components/Interview/dialogs/RescheduleDialog.vue";
import RequestToAddCommonStatus from "@/components/JobAplication/dialogs/RequestToAddCommonStatus.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AppStepper from "@/components/SharedComponent/AppStepper.vue";
import RejectReasonDialog from "@/components/JobAplication/dialogs/RejectReasonDialog.vue";
import CommentDialog from "@/components/JobAplication/dialogs/CommentDialog.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import RollbackRequest from "@/components/JobAplication/dialogs/RollbackRequest.vue";
import AddOfferDialog from "@/components/JobAplication/dialogs/AddOfferDialog.vue";
import { IconExclamationCircle, IconCircleCheck } from "@tabler/icons-vue";
import { requiredValidator } from "@/composables/Validators";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { useJobApplicationApprovalPendingStore } from "@/store/JobApplications/JobApplicationApprovalPending";
import {
  getYYYYMMDDFormat,
  getMonthDateYearTimeFormat,
  getYYYYMMDDFormatTime,
} from "@/composables/DateTime";
import moment from "moment";
import { useInterviewStore } from "@/store/JobApplications/InterviewStore";
import { toast } from "vue3-toastify";
import { useRouter } from "vue-router";
import { useJobApplicationsStore } from "@/store/JobApplications/JobApplicationsStore";
import { commentLengthValidator } from "@/composables/Validators";
const interviewStore = useInterviewStore();
const jobApplicationStore = useJobApplicationStore();
const jobApplicationsStore = useJobApplicationsStore();
const jobApprovalPendingStore = useJobApplicationApprovalPendingStore();
const router = useRouter();
//ref forms
const refInterviewFormLevel1 = ref();
const refInterviewFormLevel2 = ref();
const refInterviewFormLevel3 = ref();
const refAddPlacementForm = ref();
const refRemarkForm = ref();

// dialogs
const approveJobApplicationDialog = ref(false);
const RejectJobApplicationDialog = ref(false);
const applicationSharedConfirmDialog = ref(false);
const shortlistJobApplicationDialog = ref(false);
const completeInterviewDialog = ref(false);
const passInterviewDialog = ref(false);
const scheduleLevel2Confirmation = ref(false);
const scheduleLevel3Confirmation = ref(false);
const scheduleConfirmation = ref(false);
const acceptOfferDialog = ref(false);
const addPlacementDialog = ref(false);
const selectedApplicationDialog = ref(false);
const dialog = ref(false);
const isCurrentStepValid = ref(true);
const remarkDialog = ref(false);
const selectedStatus = ref(null);
const confirmChangeJobApplicationDialog = ref(false);
const dateTime = ref();
const comment = ref("");
const remark = ref("");
const title = ref("");
const description = ref("");
const selectedInterviewId = ref("");

const convertDate = computed(() => {
  return getYYYYMMDDFormat(dateTime.value) === "Invalid date" ||
    getYYYYMMDDFormat(dateTime.value).includes("1970-01-01")
    ? ""
    : getYYYYMMDDFormat(dateTime.value);
});
const convertInterviewDate = computed(() => {
  return getYYYYMMDDFormatTime(dateTime.value) === "Invalid date" ||
    getYYYYMMDDFormatTime(dateTime.value).includes("1970-01-01")
    ? ""
    : getYYYYMMDDFormatTime(dateTime.value);
});
const iconsSteps = [
  {
    title: "Submission",
    icon: IconExclamationCircle,
  },
  {
    title: "Screening",
    icon: IconExclamationCircle,
  },
  {
    title: "Level 1",
    icon: IconCircleCheck,
  },
  {
    title: "Level 2",
    icon: IconCircleCheck,
  },
  {
    title: "Level 3",
    icon: IconCircleCheck,
  },
  {
    title: "Selection",
    icon: IconCircleCheck,
  },
  {
    title: "Joined",
    icon: IconCircleCheck,
  },
];
const index = computed(() => {
  let value = jobApplicationStore.showApplicationStatusScreen();
  if (value) {
    return 7;
  } else {
    return iconsSteps.findIndex(
      (step) => step.title === jobApplicationStore.jobApplication.currentHiringStage
    );
  }
});
const currentStep = ref(index);

const jobApplicationsCommonStatus = computed(() => {
  const data = localStorage.getItem("jobApplicationsStatus");
  return JSON.parse(data);
});

const changeJobApplicationStatus = (status) => {
  selectedStatus.value = status;

  if (!status.remarkRequired) {
    confirmChangeJobApplicationDialog.value = true;
  }
};
const changeJobApplicationStatusWithRemark = () => {
  remark.value
    ? (confirmChangeJobApplicationDialog.value = true)
    : (confirmChangeJobApplicationDialog.value = false);
};

const OnConfirmChangeJobApplicationStatus = async () => {
  //endpoint
  let data = {
    stage: selectedStatus.value.stage,
    status: selectedStatus.value.name,
    comment: remark.value,
  };
  await jobApplicationStore.commonJobApplicationStatus(data);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};

const approvedJobApplication = async (data) => {
  await jobApprovalPendingStore.approveJobApplication(
    jobApplicationStore.jobApplication.id,
    data
  );
};
const rejectJobApplication = async (data) => {
  let req = {
    type: "Senior Rejected",
    comment: data.comment,
  };
  await jobApprovalPendingStore.rejectJobApplication(
    jobApplicationStore.jobApplication.id,
    req
  );
};

const applicationShared = async () => {
  if (convertDate.value) {
    let data = {
      sharedOn: convertDate.value,
      comment: "Application Shared",
    };
    applicationSharedConfirmDialog.value = false;
    await jobApplicationStore.sharedWithClientJobApplication(
      jobApplicationStore.jobApplication.id,
      data
    );
    await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
  } else {
    toast.error("Required Date", {
      autoClose: 1500,
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      transition: toast.TRANSITIONS.ZOOM,
    });
  }
};

const onShortlisted = async () => {
  let shortlisted = {
    shortlistedOn: getYYYYMMDDFormat(new Date()),
    comment: "",
  };
  shortlistJobApplicationDialog.value = false;
  await jobApplicationStore.shortlistedJobApplication(
    jobApplicationStore.jobApplication.id,
    shortlisted
  );
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const screeningReject = async (data) => {
  await jobApplicationStore.screeningRejectJobApplication(
    jobApplicationStore.jobApplication.id,
    data
  );
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};

//interview
const confirmScheduleInterview = (level) => {
  if (level === "L1") {
    refInterviewFormLevel1.value?.validate().then((valid) => {
      if (valid.valid) {
        scheduleConfirmation.value = true;
      }
    });
  } else if (level === "L2") {
    refInterviewFormLevel2.value?.validate().then((valid) => {
      if (valid.valid) {
        scheduleLevel2Confirmation.value = true;
      }
    });
  } else if (level === "L3") {
    refInterviewFormLevel3.value?.validate().then((valid) => {
      if (valid.valid) {
        scheduleLevel3Confirmation.value = true;
      }
    });
  }
};
const scheduleLevel1 = async () => {
  let level1 = {
    title: title.value,
    description: description.value,
    level: "L1",
    dateTime: moment(dateTime.value).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    comment: "Level 1 Interview Scheduled on the given Date and Time",
  };
  scheduleConfirmation.value = false;
  await interviewStore.scheduleInterview(level1);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const confirmCompleteInterview = (interviewId) => {
  selectedInterviewId.value = interviewId;
  completeInterviewDialog.value = true;
};
const completeInterview = async () => {
  const comment =
    "Interview has been successfully completed, and the process is now over";
  completeInterviewDialog.value = false;
  await interviewStore.completeInterview(selectedInterviewId.value, {
    comment,
  });
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const rescheduleInterview = async (rescheduleData) => {
  await interviewStore.rescheduleInterview(selectedInterviewId.value, rescheduleData);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const cancelInterview = async (comment) => {
  await interviewStore.cancelInterview(selectedInterviewId.value, comment);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const confirmPassInterview = (interviewId) => {
  selectedInterviewId.value = interviewId;
  passInterviewDialog.value = true;
};
const passInterview = async () => {
  const comment = "Interview has been Passed";
  passInterviewDialog.value = false;
  await interviewStore.passInterview(selectedInterviewId.value, { comment });
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const failInterview = async (comment) => {
  await interviewStore.failInterview(selectedInterviewId.value, comment);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const scheduleLevel2 = async () => {
  let level2 = {
    title: title.value,
    description: description.value,
    level: "L2",
    dateTime: moment(dateTime.value).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    comment: "Level 2 Interview Scheduled on the given Date and Time",
  };
  scheduleLevel2Confirmation.value = false;
  await interviewStore.scheduleInterview(level2);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
const scheduleLevel3 = async () => {
  let level3 = {
    title: title.value,
    description: description.value,
    level: "L3",
    dateTime: moment(dateTime.value).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    comment: "Level 3 Interview Scheduled on the given Date and Time",
  };
  scheduleLevel3Confirmation.value = false;
  await interviewStore.scheduleInterview(level3);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};
// selection
const selectedApplication = async () => {
  selectedApplicationDialog.value = false;
  let comment = {
    comment: "Candidate Selected",
  };
  await jobApplicationStore.selectedApplication(comment);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};

//offer
const createOffer = async (offer) => {
  await jobApplicationStore.addOffer(offer);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
  dialog.value = false;
};
const acceptOffer = async (offer) => {
  await jobApplicationStore.acceptOffer(offer);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
  dialog.value = false;
};
const rejectOffer = async (offer) => {
  await jobApplicationStore.rejectOffer(offer);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
  dialog.value = false;
};

//placement
const placementPage = () => {
  router.push("/placements");
};
const confirmAddPlacement = () => {
  refAddPlacementForm.value?.validate().then((valid) => {
    if (valid.valid) {
      addPlacementDialog.value = true;
    }
  });
};
const addPlacement = async () => {
  let data = {
    jobApplicationId: jobApplicationStore.jobApplication.id,
    expectedJoiningDate: convertDate.value,
    comment: comment.value ? comment.value : "Expected Joining Date",
  };
  addPlacementDialog.value = false;
  await jobApplicationStore.addPlacement(data);
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
  dialog.value = false;
};
const WithdrawPlacement = async (reason) => {
  await jobApplicationStore.withdrawPlacement(
    jobApplicationStore.jobApplication.id,
    reason
  );
  await jobApplicationStore.getJobApplication(jobApplicationStore.jobApplication.id);
};

watch(
  () => remarkDialog.value,
  () => {
    if (remarkDialog.value === false) {
      selectedStatus.value = null;
    }
  }
);
</script>

<template>
  <VCard height="75vh" color="#252525">
    <v-card-text class="pr-6">
      <!-- 👉 Stepper -->
      <v-row no-gutters>
        <v-col cols="12" lg="11" md="11" sm="11">
          <AppStepper
            v-model:current-step="currentStep"
            :items="iconsSteps"
            :is-active-step-valid="isCurrentStepValid"
        /></v-col>
        <v-col cols="12" lg="1" md="1" sm="1" align="end">
          <div class="d-flex">
            <v-btn
              icon="mdi-dots-vertical"
              variant="tonal"
              size="small"
              :disabled="jobApplicationStore.showCommonStatusBtn()"
              class="me-3"
            >
              <v-icon>mdi-dots-vertical</v-icon>
              <v-menu activator="parent" location="bottom">
                <v-card color="transparent" max-height="400">
                  <v-list>
                    <v-hover
                      v-slot="{ isHovering, props }"
                      v-for="status in jobApplicationsCommonStatus"
                      :key="status.id"
                    >
                      <v-list-item class="user-list-name" v-bind="props">
                        <v-chip
                          @click="changeJobApplicationStatus(status)"
                          :variant="isHovering ? 'tonal' : 'text'"
                          class="w-100 rounded"
                          size="large"
                          :style="isHovering ? `cursor: pointer` : ''"
                        >
                          <p
                            class="text-button text-capitalize"
                            block
                            flat
                            variant="text"
                          >
                            {{ status.name }}
                          </p>
                        </v-chip>

                        <v-dialog
                          v-model="remarkDialog"
                          activator="parent"
                          max-width="450"
                          v-if="status.remarkRequired"
                        >
                          <v-card color="#303030">
                            <DialogCloseBtn @close-dialog="remarkDialog = false" />

                            <v-card-text class="pa-4 pb-0">
                              <h3 class="font-weight-regular text-center mb-2">Remark</h3>
                              <v-form ref="refRemarkForm" @submit.prevent>
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <v-textarea
                                      variant="outlined"
                                      color="#b2d250"
                                      label="Remark"
                                      @input="
                                        remark = $event.target.value.replace(/\s+/g, ' ')
                                      "
                                      autofocus
                                      :rules="[requiredValidator, commentLengthValidator]"
                                      v-model="remark"
                                      counter="30"
                                    />
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions class="d-flex justify-center flex-wrap gap-3">
                              <v-btn
                                @click="changeJobApplicationStatusWithRemark()"
                                variant="tonal"
                                color="#74a942"
                              >
                                Submit
                              </v-btn>
                              <v-btn
                                @click="remarkDialog = false"
                                variant="tonal"
                                color="red"
                              >
                                Cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>
                    </v-hover>
                    <v-card-actions>
                      <v-btn
                        block
                        variant="tonal"
                        size="small"
                        color="#b2d250"
                        prepend-icon="mdi-plus"
                        >Request Status
                        <RequestToAddCommonStatus />
                      </v-btn>
                    </v-card-actions>
                  </v-list>
                </v-card>
              </v-menu>
            </v-btn>
            <v-btn
              icon="mdi-alert-circle-outline"
              variant="tonal"
              color="orange"
              size="small"
              ><v-icon>mdi-alert-circle-outline</v-icon>
              <v-tooltip activator="parent" location="bottom"> Help & Support </v-tooltip>
              <RollbackRequest />
              <!-- <HelpAndSupport/> -->
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <VDivider />

    <VCardText class="pa-1">
      <VWindow v-model="currentStep" class="disable-tab-transition">
        <VWindowItem style="height: 400px">
          <div v-if="jobApplicationStore.showCurrentStatusScreen('Initiated')">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3">
                    Is the Application shared with the Client?
                  </h3>
                  <VueDatePicker
                    v-model="dateTime"
                    :is-24="false"
                    dark
                    :clearable="false"
                    teleport-center
                    month-name-format="long"
                    ><template #dp-input>
                      <CustomTextFieldComponent
                        type="text"
                        hide-details="auto"
                        readonly
                        :model-value="convertDate"
                        prepend-inner-icon="mdi-calendar"
                        :rules="[requiredValidator]"
                        class="pb-0"
                        label="Select Date"
                      >
                      </CustomTextFieldComponent>
                    </template>
                  </VueDatePicker>
                  <v-textarea
                    label="Comment"
                    rows="2"
                    class="mt-3"
                    @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                    auto-grow
                    v-model="comment"
                    counter="30"
                  />
                  <div class="d-flex justify-center mt-8">
                    <v-btn
                      color="green"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="applicationSharedConfirmDialog = true"
                      >Next</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div v-if="jobApplicationStore.showCurrentStatusScreen('Senior Rejected')">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3">
                    Senior Reject the Candidate
                  </h3>
                </div>
              </div>
            </v-card-text>
          </div>

          <div v-if="jobApplicationStore.showCurrentStatusScreen('Senior Approved')">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center mb-5">
                    Have you shared the application with our valued client?
                  </h2>
                  <div class="mx-auto w-50">
                    <div>
                      <VueDatePicker
                        v-model="dateTime"
                        :is-24="false"
                        dark
                        :clearable="false"
                        :enable-time-picker="false"
                        teleport-center
                        month-name-format="long"
                        ><template #dp-input>
                          <CustomTextFieldComponent
                            type="text"
                            hide-details="auto"
                            readonly
                            :model-value="convertDate"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[requiredValidator]"
                            class="pb-0"
                            label="Select Date"
                          >
                          </CustomTextFieldComponent>
                        </template>
                      </VueDatePicker>
                    </div>
                  </div>
                  <div class="d-flex justify-center mt-8">
                    <v-btn
                      color="green"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="applicationSharedConfirmDialog = true"
                      >Shared</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem style="height: 400px">
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Shared with Client')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Kindly Elevate Application Status
                  </h2>
                  <div class="d-flex justify-center mt-8">
                    <v-btn
                      color="green"
                      variant="outlined"
                      @click="shortlistJobApplicationDialog = true"
                      class="yesButtonStyle me-8"
                      >Shortlisted
                    </v-btn>
                    <v-btn color="red" variant="outlined" class="yesButtonStyle me-8"
                      >Rejected

                      <CommentDialog
                        title="Screening Reject"
                        @screening-reject="screeningReject"
                      />
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Shortlisted')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3 mb-5">
                    Fantastic! please share the details of the upcoming interview with
                    Date & Time?
                  </h3>

                  <v-form ref="refInterviewFormLevel1">
                    <div class="w-50 mx-auto">
                      <CustomTextFieldComponent
                        label="Title"
                        @input="title = $event.target.value.replace(/\s+/g, ' ')"
                        :rules="[requiredValidator]"
                        v-model="title"
                      />
                      <CustomTextFieldComponent
                        label="Description (Optional)"
                        v-model="description"
                        @input="description = $event.target.value.replace(/\s+/g, ' ')"
                      />
                      <VueDatePicker
                        v-model="dateTime"
                        :is-24="false"
                        :clearable="false"
                        dark
                        teleport-center
                        month-name-format="long"
                        ><template #dp-input>
                          <CustomTextFieldComponent
                            type="text"
                            hide-details="auto"
                            readonly
                            :model-value="convertInterviewDate"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[requiredValidator]"
                            class="pb-0"
                            label="Select Interview Date & Time"
                          >
                          </CustomTextFieldComponent>
                        </template>
                      </VueDatePicker>
                    </div>
                  </v-form>

                  <div class="d-flex justify-center mt-2">
                    <v-btn
                      color="green"
                      variant="outlined"
                      class="yesButtonStyle"
                      @click="confirmScheduleInterview('L1')"
                      >Schedule</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div v-if="jobApplicationStore.showCurrentStatusScreen('Rejected')">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Dear Recruiter, kindly note that the candidate's screening status is
                    marked as rejected
                  </h2>
                  <h2 class="font-weight-regular text-center ma-3">
                    Thank you for your attention to this matter.
                  </h2>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Scheduled')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center">
                    Kindly provide an update on the status of Level 1
                  </h2>

                  <div class="d-flex justify-center mt-5">
                    <v-card
                      color="transparent"
                      border
                      class="mx-auto"
                      width="380"
                      v-for="interview in jobApplicationStore.jobApplication.interviews"
                      :key="interview"
                    >
                      <div v-if="interview.level === 'Level 1'">
                        <v-card-text>
                          <div class="d-flex justify-space-between">
                            <div>
                              <h3 class="font-weight-regular">
                                {{ interview.title }}
                              </h3>
                              <h4 class="font-weight-regular text-grey-lighten-2 mt-2">
                                {{ getMonthDateYearTimeFormat(interview.dateTime) }}
                              </h4>
                            </div>
                            <div>
                              <v-chip rounded="lg" class="pa-2" size="medium">{{
                                interview.status
                              }}</v-chip>
                            </div>
                          </div>

                          <div class="d-flex justify-center mt-5">
                            <v-btn
                              color="green"
                              variant="outlined"
                              class="yesButtonStyle"
                              prepend-icon="mdi-check"
                              @click="confirmCompleteInterview(interview.id)"
                              >Complete
                              <!-- <CommentDialog
                                    title="Complete Interview"
                                    @complete-interview="completeInterview"
                                  /> -->
                            </v-btn>
                          </div>

                          <div class="mt-4 d-flex justify-center">
                            <v-btn
                              @click="selectedInterviewId = interview.id"
                              prepend-icon="mdi-calendar"
                              color="blue"
                              class="me-3"
                              variant="outlined"
                              ><p>Reschedule</p>
                              <RescheduleDialog
                                title="Reschedule Interview"
                                @reschedule-intervew="rescheduleInterview"
                              />
                            </v-btn>
                            <v-btn
                              @click="selectedInterviewId = interview.id"
                              variant="outlined"
                              color="red"
                              prepend-icon="mdi-cancel"
                              ><p>Cancel</p>
                              <CommentDialog
                                @cancel-interview="cancelInterview"
                                title="Cancel Interview"
                            /></v-btn>
                          </div>
                        </v-card-text>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Rescheduled')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    The Level 1 Interview has been rescheduled
                  </h2>
                  <v-card
                    color="transparent"
                    border
                    class="mx-auto"
                    width="380"
                    v-for="interview in jobApplicationStore.jobApplication.interviews"
                    :key="interview"
                  >
                    <div v-if="interview.level === 'Level 1'">
                      <v-card-text>
                        <div class="d-flex justify-space-between">
                          <div>
                            <h3 class="font-weight-regular">
                              {{ interview.title }}
                            </h3>
                            <h4 class="font-weight-regular text-grey-lighten-2 mt-2">
                              {{ getMonthDateYearTimeFormat(interview.dateTime) }}
                            </h4>
                          </div>
                          <div>
                            <v-chip rounded="lg" class="pa-2" size="medium">{{
                              interview.status
                            }}</v-chip>
                          </div>
                        </div>

                        <div class="d-flex justify-center mt-5">
                          <v-btn
                            color="green"
                            variant="outlined"
                            class="yesButtonStyle"
                            prepend-icon="mdi-check"
                            @click="confirmCompleteInterview(interview.id)"
                            >Complete
                            <!-- <CommentDialog
                                    title="Complete Interview"
                                    @complete-interview="completeInterview"
                                  /> -->
                          </v-btn>
                        </div>

                        <div class="mt-4 d-flex justify-center">
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            prepend-icon="mdi-calendar"
                            color="blue"
                            class="me-3"
                            variant="outlined"
                            ><p>Reschedule</p>
                            <RescheduleDialog
                              title="Reschedule Interview"
                              @reschedule-intervew="rescheduleInterview"
                            />
                          </v-btn>
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            variant="outlined"
                            color="red"
                            prepend-icon="mdi-cancel"
                            ><p>Cancel</p>
                            <CommentDialog
                              @cancel-interview="cancelInterview"
                              title="Cancel Interview"
                          /></v-btn>
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Completed')"
          >
            <div
              v-for="interview in jobApplicationStore.jobApplication.interviews"
              :key="interview"
            >
              <div v-if="interview.level === 'Level 1'">
                <v-card-text>
                  <div class="d-flex justify-center">
                    <div>
                      <h2 class="font-weight-regular text-center ma-3 me-3">
                        The interview has been completed!
                      </h2>
                      <h3
                        class="font-weight-regular text-lighten-grey text-center ma-3 mt-5"
                      >
                        Please provide an update on the status of Level 1 interview. Thank
                        you!
                      </h3>
                      <div class="d-flex justify-center mt-8">
                        <v-btn
                          color="green"
                          variant="outlined"
                          class="yesButtonStyle me-8"
                          @click="confirmPassInterview(interview.id)"
                          >Pass
                          <!-- <CommentDialog
                                title="Pass Interview"
                                @pass-interview="passInterview"
                              /> -->
                        </v-btn>
                        <v-btn
                          color="red"
                          variant="outlined"
                          class="yesButtonStyle me-8"
                          @click="selectedInterviewId = interview.id"
                          >Fail
                          <CommentDialog
                            title="Fail Interview"
                            @fail-interview="failInterview"
                        /></v-btn>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Passed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <div>
                    <h2 class="font-weight-regular text-center ma-3 mt-0">
                      Is there a Level 2 interview round ?
                    </h2>
                  </div>
                  <div class="d-flex justify-center">
                    <v-card-text>
                      <div class="d-flex justify-center mr-8">
                        <div>
                          <h3 class="font-weight-regular text-center mb-5">
                            Please share the details of the upcoming interview with Date &
                            Time?
                          </h3>
                          <v-form ref="refInterviewFormLevel2">
                            <div class="w-50 mx-auto">
                              <CustomTextFieldComponent
                                label="Title"
                                @input="title = $event.target.value.replace(/\s+/g, ' ')"
                                :rules="[requiredValidator]"
                                v-model="title"
                              />
                              <CustomTextFieldComponent
                                label="Description (Optional)"
                                v-model="description"
                                @input="
                                  description = $event.target.value.replace(/\s+/g, ' ')
                                "
                              />
                              <VueDatePicker
                                v-model="dateTime"
                                :is-24="false"
                                :clearable="false"
                                dark
                                teleport-center
                                month-name-format="long"
                              >
                                <template #dp-input>
                                  <CustomTextFieldComponent
                                    type="text"
                                    hide-details="auto"
                                    readonly
                                    :model-value="convertInterviewDate"
                                    prepend-inner-icon="mdi-calendar"
                                    :rules="[requiredValidator]"
                                    class="pb-0"
                                    label="Select Interview Date & Time"
                                  >
                                  </CustomTextFieldComponent>
                                </template>
                              </VueDatePicker>
                            </div>
                          </v-form>

                          <div class="d-flex justify-center mt-2">
                            <v-btn
                              color="green"
                              variant="outlined"
                              class="yesButtonStyle me-8"
                              @click="confirmScheduleInterview('L2')"
                              >Schedule</v-btn
                            >
                          </div>
                        </div>
                      </div>
                    </v-card-text>

                    <div class="d-flex">
                      <v-divider vertical class="me-10"></v-divider>
                      <v-btn
                        class="mx-auto"
                        style="align-self: center"
                        color="orange"
                        variant="tonal"
                        @click="selectedApplicationDialog = true"
                        >Selected
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Scheduled')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Kindly provide an update on the status of Level 2
                  </h2>
                  <v-card
                    color="transparent"
                    border
                    class="mx-auto"
                    width="380"
                    v-for="interview in jobApplicationStore.jobApplication.interviews"
                    :key="interview"
                  >
                    <div v-if="interview.level === 'Level 2'">
                      <v-card-text>
                        <div class="d-flex justify-space-between">
                          <div>
                            <h3 class="font-weight-regular">
                              {{ interview.title }}
                            </h3>
                            <h4 class="font-weight-regular text-grey-lighten-2">
                              {{ getMonthDateYearTimeFormat(interview.dateTime) }}
                            </h4>
                          </div>
                          <div>
                            <v-chip rounded="lg" class="pa-2" size="medium">{{
                              interview.status
                            }}</v-chip>
                          </div>
                        </div>

                        <div class="d-flex justify-center mt-5">
                          <v-btn
                            color="green"
                            variant="outlined"
                            class="yesButtonStyle"
                            prepend-icon="mdi-check"
                            @click="confirmCompleteInterview(interview.id)"
                            >Complete
                            <!-- <CommentDialog
                                    title="Complete Interview"
                                    @complete-interview="completeInterview"
                                  /> -->
                          </v-btn>
                        </div>

                        <div class="mt-4 d-flex justify-center">
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            prepend-icon="mdi-calendar"
                            color="blue"
                            class="me-3"
                            variant="outlined"
                            ><p>Reschedule</p>
                            <RescheduleDialog
                              title="Reschedule Interview"
                              @reschedule-intervew="rescheduleInterview"
                            />
                          </v-btn>
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            variant="outlined"
                            color="red"
                            prepend-icon="mdi-cancel"
                            ><p>Cancel</p>
                            <CommentDialog
                              @cancel-interview="cancelInterview"
                              title="Cancel Interview"
                          /></v-btn>
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Rescheduled')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    The Level 2 Interview has been rescheduled
                  </h2>
                  <v-card
                    color="transparent"
                    border
                    class="mx-auto"
                    width="380"
                    v-for="interview in jobApplicationStore.jobApplication.interviews"
                    :key="interview"
                  >
                    <div v-if="interview.level === 'Level 2'">
                      <v-card-text>
                        <div class="d-flex justify-space-between">
                          <div>
                            <h3 class="font-weight-regular">
                              {{ interview.title }}
                            </h3>
                            <h4 class="font-weight-regular text-grey-lighten-2">
                              {{ getYYYYMMDDFormat(interview.dateTime) }}
                            </h4>
                          </div>
                          <div>
                            <v-chip rounded="lg" class="pa-2" size="medium">{{
                              interview.status
                            }}</v-chip>
                          </div>
                        </div>

                        <div class="d-flex justify-center mt-5">
                          <v-btn
                            color="green"
                            variant="outlined"
                            class="yesButtonStyle"
                            prepend-icon="mdi-check"
                            @click="confirmCompleteInterview(interview.id)"
                            >Complete
                            <!-- <CommentDialog
                                    title="Complete Interview"
                                    @complete-interview="completeInterview"
                                  /> -->
                          </v-btn>
                        </div>

                        <div class="mt-4 d-flex justify-center">
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            prepend-icon="mdi-calendar"
                            color="blue"
                            class="me-3"
                            variant="outlined"
                            ><p>Reschedule</p>
                            <RescheduleDialog
                              title="Reschedule Interview"
                              @reschedule-intervew="rescheduleInterview"
                            />
                          </v-btn>
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            variant="outlined"
                            color="red"
                            prepend-icon="mdi-cancel"
                            ><p>Cancel</p>
                            <CommentDialog
                              @cancel-interview="cancelInterview"
                              title="Cancel Interview"
                          /></v-btn>
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Completed')"
          >
            <div
              v-for="interview in jobApplicationStore.jobApplication.interviews"
              :key="interview"
            >
              <div v-if="interview.level === 'Level 2'">
                <v-card-text>
                  <div class="d-flex justify-center">
                    <div>
                      <h2 class="font-weight-regular text-center ma-3 me-3">
                        The interview has been completed!
                      </h2>
                      <h3
                        class="font-weight-regular text-lighten-grey text-center ma-3 mt-5"
                      >
                        Please provide an update on the status of Level 2 interview. Thank
                        you!
                      </h3>
                      <div class="d-flex justify-center mt-8">
                        <v-btn
                          color="green"
                          variant="outlined"
                          class="yesButtonStyle me-8"
                          @click="confirmPassInterview(interview.id)"
                          >Pass
                          <!-- <CommentDialog
                                title="Pass Interview"
                                @pass-interview="passInterview"
                              /> -->
                        </v-btn>
                        <v-btn
                          color="red"
                          variant="outlined"
                          class="yesButtonStyle me-8"
                          @click="selectedInterviewId = interview.id"
                          >Fail
                          <CommentDialog
                            title="Fail Interview"
                            @fail-interview="failInterview"
                        /></v-btn>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Passed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <div>
                    <h2 class="font-weight-regular text-center ma-3 mt-0">
                      Is there any next Level 3 interview round ?
                    </h2>
                  </div>
                  <div class="d-flex justify-center">
                    <v-card-text>
                      <div class="d-flex justify-center mr-8">
                        <div>
                          <h3 class="font-weight-regular text-center ma-2 mb-5">
                            Please share the details of the upcoming interview with Date &
                            Time?
                          </h3>
                          <v-form ref="refInterviewFormLevel3">
                            <div class="w-50 mx-auto">
                              <CustomTextFieldComponent
                                label="Title"
                                :rules="[requiredValidator]"
                                v-model="title"
                                @input="title = $event.target.value.replace(/\s+/g, ' ')"
                              />
                              <CustomTextFieldComponent
                                label="Description (Optional)"
                                v-model="description"
                                @input="
                                  description = $event.target.value.replace(/\s+/g, ' ')
                                "
                              />
                              <VueDatePicker
                                v-model="dateTime"
                                :is-24="false"
                                dark
                                teleport-center
                                :clearable="false"
                                month-name-format="long"
                                ><template #dp-input>
                                  <CustomTextFieldComponent
                                    type="text"
                                    hide-details="auto"
                                    readonly
                                    :model-value="convertInterviewDate"
                                    prepend-inner-icon="mdi-calendar"
                                    :rules="[requiredValidator]"
                                    class="pb-0"
                                    label="Select Interview Date & Time"
                                  />
                                </template>
                              </VueDatePicker>
                            </div>
                          </v-form>

                          <div class="d-flex justify-center mt-2">
                            <v-btn
                              color="green"
                              variant="outlined"
                              class="yesButtonStyle me-8"
                              @click="confirmScheduleInterview('L3')"
                              >Schedule</v-btn
                            >
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                    <div class="d-flex">
                      <v-divider vertical class="me-10"></v-divider>

                      <v-btn
                        class="mx-auto"
                        style="align-self: center"
                        color="orange"
                        variant="tonal"
                        @click="selectedApplicationDialog = true"
                        >Selected
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Failed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3 me-3">
                    Unfortunately, the candidate wasn't successful in the interview this
                    time.
                  </h2>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Scheduled')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Kindly provide an update on the status of Level 3
                  </h2>
                  <v-card
                    color="transparent"
                    border
                    class="mx-auto"
                    width="380"
                    v-for="interview in jobApplicationStore.jobApplication.interviews"
                    :key="interview"
                  >
                    <div v-if="interview.level === 'Level 3'">
                      <v-card-text>
                        <div class="d-flex justify-space-between">
                          <div>
                            <h3 class="font-weight-regular">
                              {{ interview.title }}
                            </h3>
                            <h4 class="font-weight-regular text-grey-lighten-2">
                              {{ getMonthDateYearTimeFormat(interview.dateTime) }}
                            </h4>
                          </div>
                          <div>
                            <v-chip rounded="lg" class="pa-2" size="medium">{{
                              interview.status
                            }}</v-chip>
                          </div>
                        </div>

                        <div class="d-flex justify-center mt-5">
                          <v-btn
                            color="green"
                            variant="outlined"
                            class="yesButtonStyle"
                            prepend-icon="mdi-check"
                            @click="confirmCompleteInterview(interview.id)"
                            >Complete
                            <!-- <CommentDialog
                                    title="Complete Interview"
                                    @complete-interview="completeInterview"
                                  /> -->
                          </v-btn>
                        </div>

                        <div class="mt-4 d-flex justify-center">
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            prepend-icon="mdi-calendar"
                            color="blue"
                            class="me-3"
                            variant="outlined"
                            ><p>Reschedule</p>
                            <RescheduleDialog
                              title="Reschedule Interview"
                              @reschedule-intervew="rescheduleInterview"
                            />
                          </v-btn>
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            variant="outlined"
                            color="red"
                            prepend-icon="mdi-cancel"
                            ><p>Cancel</p>
                            <CommentDialog
                              @cancel-interview="cancelInterview"
                              title="Cancel Interview"
                          /></v-btn>
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Rescheduled')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    The Level 3 Interview has been rescheduled
                  </h2>
                  <v-card
                    color="transparent"
                    border
                    class="mx-auto"
                    width="380"
                    v-for="interview in jobApplicationStore.jobApplication.interviews"
                    :key="interview"
                  >
                    <div v-if="interview.level === 'Level 3'">
                      <v-card-text>
                        <div class="d-flex justify-space-between">
                          <div>
                            <h3 class="font-weight-regular">
                              {{ interview.title }}
                            </h3>
                            <h4 class="font-weight-regular text-grey-lighten-2">
                              {{ getYYYYMMDDFormat(interview.dateTime) }}
                            </h4>
                          </div>
                          <div>
                            <v-chip rounded="lg" class="pa-2" size="medium">{{
                              interview.status
                            }}</v-chip>
                          </div>
                        </div>

                        <div class="d-flex justify-center mt-5">
                          <v-btn
                            color="green"
                            variant="outlined"
                            class="yesButtonStyle"
                            prepend-icon="mdi-check"
                            @click="confirmCompleteInterview(interview.id)"
                            >Complete
                            <!-- <CommentDialog
                                    title="Complete Interview"
                                    @complete-interview="completeInterview"
                                  /> -->
                          </v-btn>
                        </div>

                        <div class="mt-4 d-flex justify-center">
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            prepend-icon="mdi-calendar"
                            color="blue"
                            class="me-3"
                            variant="outlined"
                            ><p>Reschedule</p>
                            <RescheduleDialog
                              title="Reschedule Interview"
                              @reschedule-intervew="rescheduleInterview"
                            />
                          </v-btn>
                          <v-btn
                            @click="selectedInterviewId = interview.id"
                            variant="outlined"
                            color="red"
                            prepend-icon="mdi-cancel"
                            ><p>Cancel</p>
                            <CommentDialog
                              @cancel-interview="cancelInterview"
                              title="Cancel Interview"
                          /></v-btn>
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Completed')"
          >
            <div
              v-for="interview in jobApplicationStore.jobApplication.interviews"
              :key="interview"
            >
              <div v-if="interview.level === 'Level 3'">
                <v-card-text>
                  <div class="d-flex justify-center">
                    <div>
                      <h2 class="font-weight-regular text-center ma-3 me-3">
                        The interview has been completed!
                      </h2>
                      <h3
                        class="font-weight-regular text-lighten-grey text-center ma-3 mt-5"
                      >
                        Please provide an update on the status of Level 3 interview. Thank
                        you!
                      </h3>
                      <div class="d-flex justify-center mt-8">
                        <v-btn
                          color="green"
                          variant="outlined"
                          class="yesButtonStyle me-8"
                          @click="confirmPassInterview(interview.id)"
                          >Pass
                          <!-- <CommentDialog
                                title="Pass Interview"
                                @pass-interview="passInterview"
                              /> -->
                        </v-btn>
                        <v-btn
                          color="red"
                          variant="outlined"
                          class="yesButtonStyle me-8"
                          @click="selectedInterviewId = interview.id"
                          >Fail
                          <CommentDialog
                            title="Fail Interview"
                            @fail-interview="failInterview"
                        /></v-btn>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </div>

          <!-- selected  -->
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Passed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    kindly confirm if the candidate has been selected for the job
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-5">
                    Looking forward to sharing the offer details. Thank you!
                  </h3>
                  <div class="d-flex justify-center">
                    <v-btn
                      color="orange"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="selectedApplicationDialog = true"
                      >Selected
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Interview Failed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3 me-3">
                    Unfortunately, the candidate wasn't successful in the interview this
                    time.
                  </h2>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <!-- offer  -->
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Selected')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Congratulations!🎉 Well done on reaching this milestone.
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-5">
                    Could you please provide the details of the offer? Exciting times
                    ahead!
                  </h3>
                  <div class="d-flex justify-center">
                    <v-btn
                      color="green"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="dialog = true"
                      >Create Offer
                      <AddOfferDialog
                        @add-offer="createOffer"
                        :dialog="dialog"
                        title="Add OfferF"
                      />
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Offer Received')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Did the candidate confirm their acceptance of the offer?
                  </h2>
                  <div class="d-flex justify-center mt-8">
                    <v-btn
                      color="green"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="acceptOfferDialog = true"
                      >Accept
                      <AcceptOfferDialog
                        :offerData="offer"
                        @accept-offer="acceptOffer"
                        :dialog="acceptOfferDialog"
                      />
                    </v-btn>
                    <v-btn color="red" variant="outlined" class="yesButtonStyle me-8"
                      >Reject
                      <CommentDialog title="Reject Offer" @reject-offer="rejectOffer"
                    /></v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Offer Accepted')"
          >
            <div>
              <v-card-text>
                <div class="d-flex justify-center">
                  <div>
                    <h2 class="font-weight-regular text-center ma-3 me-3">
                      Fantastic news!🥳 The offer has been accepted. Add Joining Details
                    </h2>

                    <div class="mx-auto w-50">
                      <v-form ref="refAddPlacementForm">
                        <div>
                          <VueDatePicker
                            v-model="dateTime"
                            :is-24="false"
                            dark
                            :clearable="false"
                            :enable-time-picker="false"
                            class="mt-5"
                            teleport-center
                            month-name-format="long"
                            ><template #dp-input>
                              <CustomTextFieldComponent
                                type="text"
                                hide-details="auto"
                                readonly
                                :model-value="convertDate"
                                prepend-inner-icon="mdi-calendar"
                                :rules="[requiredValidator]"
                                class="pb-0"
                                label="Select Date"
                              >
                              </CustomTextFieldComponent>
                            </template>
                          </VueDatePicker>
                          <v-textarea
                            label="Comment(Optional)"
                            counter="30"
                            rows="2"
                            @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                            color="#b2d250"
                            variant="outlined"
                            class="mt-5"
                            v-model="comment"
                          />
                        </div>
                      </v-form>
                    </div>

                    <div class="d-flex justify-center">
                      <v-btn
                        color="green"
                        variant="outlined"
                        class="yesButtonStyle me-8"
                        @click="confirmAddPlacement()"
                        >Add Joining
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </div>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Placement Initiated')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Great job!👏 Your candidate has been successfully placed
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-5">
                    Congratulations on this achievement
                  </h3>
                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Placement Completed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Great job!👏 Your candidate has been successfully placed
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-5">
                    Congratulations on this achievement
                  </h3>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Placement On Hold')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Our candidate is currently
                    <span class="text-yellow">on hold</span> in the job application
                    process.
                  </h2>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Placement Withdrawn')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    The Placement has been Withdrawn
                  </h2>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Placement Rejected')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    The Placement has been
                    <span class="text-red">rejected</span>
                  </h2>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <!-- offer  -->
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Placement Completed')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Congratulations!🎉 Well done on reaching this milestone.
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-5">
                    Great job!👏 Your candidate has been successfully placed
                  </h3>
                </div>
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  color="yellow"
                  variant="outlined"
                  class="yesButtonStyle me-8"
                  @click="dialog = true"
                  >withdrawn
                  <RejectReasonDialog title="Withdraw" @submit="WithdrawPlacement" />
                </v-btn>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div transition="scroll-x-transition">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3">
                    {{
                      `Dear ${
                        jobApplicationStore.jobApplication?.recruiter.name?.split(" ")[0]
                      }, Current Status of application is`
                    }}
                    <span
                      :class="`text-${jobApplicationsStore.applicationStatusColor(
                        jobApplicationStore.jobApplication?.status,
                        jobApplicationStore.jobApplication?.currentHiringStage,
                        jobApplicationStore.jobApplication?.currentHiringStatus
                      )}`"
                    >
                      {{
                        jobApplicationStore.jobApplication?.currentHiringStatus
                          .toLocaleLowerCase()
                          .includes("lapsed")
                          ? "System Reject"
                          : jobApplicationStore.jobApplication?.currentHiringStatus
                      }}
                    </span>
                  </h3>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>
      </VWindow>
    </VCardText>

    <div
      v-if="jobApplicationStore.showApprovalPendingBluerScreen()"
      class="pendingApproval"
    >
      <div class="pendingContainer">
        <v-icon size="70" color="grey-lighten-2">mdi-lock-outline</v-icon>
        <h3 class="text-font-regular text-gery-lighten-2 mt-2">
          {{ jobApplicationStore.showMessageOnBlurScreen() }}
        </h3>
        <div
          class="d-flex justify-center mt-4"
          v-if="jobApplicationStore.showApprovePendingButtons()"
        >
          <v-btn
            variant="flat"
            color="#b2d250"
            :disabled="jobApplicationStore.isApproveButtonDisabled()"
            rounded="lg"
            class="me-4"
            @click="approveJobApplicationDialog = true"
            >Approve

            <CommentDialog
              v-model="approveJobApplicationDialog"
              :clientId="jobApplicationStore.jobApplication.job.client.id"
              title="Approve Job Application"
              option="optional"
              @close-dialog="approveJobApplicationDialog = false"
              @approve-jobApplication="approvedJobApplication"
            />
          </v-btn>
          <v-btn variant="flat" color="red" rounded="lg"
            >Reject
            <CommentDialog
              v-model="RejectJobApplicationDialog"
              title="Reject Job Application"
              @close="RejectJobApplicationDialog = false"
              @reject-jobApplication="rejectJobApplication"
            />
          </v-btn>
        </div>
      </div>
    </div>
  </VCard>

  <LoaderDialog :model-value="jobApplicationStore.isLoading" />

  <ConfirmationDialoge
    v-model="applicationSharedConfirmDialog"
    @action-yes="applicationShared()"
    @update-model="applicationSharedConfirmDialog = false"
    title="Application Shared"
    text="Are you sure you have Shared The Application?"
  />
  <ConfirmationDialoge
    v-model="completeInterviewDialog"
    @action-yes="completeInterview()"
    @update-model="completeInterviewDialog = false"
    title="Complete Interview"
    text="Are you sure you want to Complete Interview?"
  />
  <ConfirmationDialoge
    v-model="passInterviewDialog"
    @action-yes="passInterview()"
    @update-model="passInterviewDialog = false"
    title="Pass Interview"
    text="Are you sure you want to Pass Interview?"
  />
  <ConfirmationDialoge
    v-model="shortlistJobApplicationDialog"
    @action-yes="onShortlisted()"
    @update-model="shortlistJobApplicationDialog = false"
    title="Shortlisted"
    text="Are you sure you want to Shortlist? "
  />
  <ConfirmationDialoge
    v-model="scheduleConfirmation"
    @action-yes="scheduleLevel1()"
    @update-model="scheduleConfirmation = false"
    title="Schedule Interview"
    text="Confirm to Schedule Interview ? "
  />
  <ConfirmationDialoge
    v-model="scheduleLevel2Confirmation"
    @action-yes="scheduleLevel2()"
    @update-model="scheduleLevel2Confirmation = false"
    title="Schedule Interview"
    text="Confirm to Schedule Interview ? "
  />
  <ConfirmationDialoge
    v-model="scheduleLevel3Confirmation"
    @action-yes="scheduleLevel3()"
    @update-model="scheduleLevel3Confirmation = false"
    title="Schedule Interview"
    text="Confirm to Schedule Interview ? "
  />
  <ConfirmationDialoge
    v-model="selectedApplicationDialog"
    @action-yes="selectedApplication()"
    @update-model="selectedApplicationDialog = false"
    title="Selection"
    text="Are you sure on Selection?"
  />
  <ConfirmationDialoge
    v-model="addPlacementDialog"
    @action-yes="addPlacement()"
    @update-model="addPlacementDialog = false"
    title="Add Joining"
    text="Are you sure you want to Add Joining?"
  />
  <ConfirmationDialoge
    v-model="confirmChangeJobApplicationDialog"
    @action-yes="OnConfirmChangeJobApplicationStatus()"
    @update-model="confirmChangeJobApplicationDialog = false"
    :title="selectedStatus?.name"
    :text="`Are you sure ${selectedStatus?.name}?`"
  />
</template>

<style lang="scss" scoped>
.pendingApproval {
  background-color: rgb(255 255 255/0.025);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  padding: 20px 40px;
  height: 110vh;
  width: 200%;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.025);
}

.pendingContainer {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  font-family: sans-serif;
}

@media screen and (max-width: 1366px) {
  .pendingApproval {
    top: 70%;
    left: 10%;
    transform: translate(-50%, -50%);
  }
  .pendingContainer {
    top: 30%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
}
</style>
