import { defineStore } from "pinia";

export const useDrawerStore = defineStore("drawerStore", {
  state: () => ({
    drawerOpen: true,
  }),
  actions: {
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
  },
});

export const useAddUserFormStore = defineStore("addUserFromStore", {
  state: () => ({
    formDrawer: false,
  }),
  actions: {
    toggleFormDrawer() {
      this.formDrawer = !this.formDrawer;
    },
  },
});

export const useDialogStore = defineStore("dialogStore", {
  state: () => ({
    addPortalsDialog: false,
    portalsListDialog: false,
    confirmationDialog: false,
    memberListConfirmation: false,
    successDialog: false,
    formDrawer: false,
    aggrementForm: false,
    limitCandidatesDialog: false,
    timeOutError: false,
    statusListDialog: false,
    stagesListDialog: false,
  }),
});
