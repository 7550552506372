import { defineStore } from "pinia";
import api from "@/services/api";
import { toast } from "vue3-toastify";
import VueCookies from "vue-cookies";

export const useTeamStore = defineStore("teamStore", {
  state: () => ({
    user: VueCookies.get("user"),
    noTeamFoundDialog: false,
    memberListDialog: false,
    portalsListDialog: false,
    isLoading: false,
    isActive: true,
    userTab: 0,
    teams: [],
    team: null,
    teamId: "",
    myTeam: {},
    selectedMember: {},
    search: "",
    searchTeam: "",
    users: [],
    teamPortalsData: [],
  }),
  actions: {
    toggleMemberListDialog(value) {
      this.memberListDialog = value;
    },
    toggleTeamActiveStatus() {
      this.isActive = !this.isActive;
      this.getTeams();
    },
    refreshTeams() {
      if (
        this.user?.role === "SUPER_USER" ||
        this.user?.role === "ADMIN" ||
        this.user?.role === "HR"
      ) {
        this.getTeams();
      } else {
        this.getTeamsByManager(this.user?.id);
      }
    },
    getTeamDetails(id) {
      this.teamId = id;
      this.router.push({ name: "Team Details", params: { id: id } });
    },
    viewTeam(teamId) {
      this.teamId = teamId;
      localStorage.setItem("teamId", teamId);
      const getTeamId = localStorage.getItem("teamId");

      if (getTeamId == "null") {
        this.noTeamFoundDialog = true;
      } else {
        this.router.push(`/team-details/${getTeamId}`);
      }
    },
    viewDailyAllocations(teamId) {
      this.teamId = teamId;
      localStorage.setItem("teamId", teamId);
      const getTeamId = localStorage.getItem("teamId");

      if (getTeamId == "null") {
        this.noTeamFoundDialog = true;
      } else {
        this.router.push("/daily-allocations");
      }
    },
    refreshTeam(teamId) {
      this.getTeam(teamId);
    },
    async getTeams() {
      this.isLoading = true;
      await api
        .get(`/teams/active/${this.isActive}`)
        .then((res) => {
          this.teams = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          return err;
        });
    },
    async getTeamsByManager(managerId) {
      this.isLoading = true;
      await api
        .get(`/teams/manager/${managerId}`)
        .then((res) => {
          this.teams = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          return err;
        });
    },
    async addTeam(team) {
      (this.isLoading = true),
        await api
          .post("/teams", team)
          .then((res) => {
            this.teams.push(res.data);
            this.isLoading = false;
            toast.success("Team Added", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async getTeam(teamId) {
      this.isLoading = true;
      await api
        .get(`/teams/${teamId}`)
        .then((res) => {
          (this.team = res.data), (this.isLoading = false);
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async activateTeam(teamId) {
      this.isLoading = true;
      await api
        .put(`/teams/${teamId}/activate`)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactivateTeam(teamId) {
      this.isLoading = true;
      await api
        .put(`/teams/${teamId}/deactivate`)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addMember(memberData) {
      (this.isLoading = true),
        await api
          .post(`/teams/${memberData.teamId}/add-member`, {
            memberId: memberData.memberId,
            effectiveFrom: memberData.effectiveFrom,
          })
          .then((res) => {
            this.team.members.push(res.data);
            this.isLoading = false;
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async removeMember(memberData) {
      (this.isLoading = true),
        await api
          .post(`/teams/${memberData.teamId}/remove-member`, {
            memberId: memberData.memberId,
            effectiveTo: memberData.effectiveTo,
          })
          .then((res) => {
            this.team.members.splice(memberData.index, 1);

            this.isLoading = false;
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async addPortals(portalsIds, teamId) {
      (this.isLoading = true),
        await api
          .post("/teams/add-portals", {
            teamId: this.team ? this.team.id : teamId,
            portalIds: portalsIds,
          })
          .then((res) => {
            this.isLoading = false;
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async getTeamPortals(teamId) {
      this.isLoading = true;
      await api
        .get(`/portal/team/${teamId}`)
        .then((res) => {
          this.teamPortalsData = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getMyTeam(userId) {
      this.isLoading = true;
      await api
        .get(`/team/member/${userId}`)
        .then((res) => {
          this.myTeam = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },
  getters: {
    getformattedTeams() {
      const formattedTeams = this.teams.map((team) => ({
        id: team.id,
        teamName: team.name,
        managerName: team.manager?.fullName,
        leaderName: team.leader?.fullName,
        membersCount: Array.isArray(team.members) ? team.members.length : 0,
      }));
      const searchTeamData = this.searchTeam
        ? formattedTeams.filter((team) =>
            team.teamName
              .toLocaleLowerCase()
              .includes(this.searchTeam.toLocaleLowerCase())
          )
        : formattedTeams;
      return searchTeamData.sort((a, b) => b.membersCount - a.membersCount);
    },
    teamMembers() {
      if (
        this.team &&
        this.team.members &&
        Array.isArray(this.team.members) &&
        this.team.members.length > 0
      ) {
        const member = this.team.members.map((member) => ({
          id: member.id,
          memberName: member?.fullName,
        }));
        return member || null;
      }
      return null;
    },
    teamPortals() {
      if (
        this.team &&
        this.team.portals &&
        Array.isArray(this.team.portals) &&
        this.team.portals.length > 0
      ) {
        const portal = this.team.portals.map((portal) => ({
          id: portal.id,
          name: portal.name,
          accountId: portal.accountID,
          password: portal.password,
          otp: portal.otp,
        }));
        return portal || null;
      }
      return null;
    },
    members() {
      return this.myTeam.members;
    },
    searchMembers() {
      if (this.search) {
        return this.users.filter((member) => {
          return member?.fullName
            .toLocaleLowerCase()
            .includes(this.search?.toLocaleLowerCase());
        });
      } else {
        return this.users;
      }
    },
  },
});
