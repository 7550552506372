<template>
  <v-dialog v-model="dialog" activator="parent" max-width="1000">
    <v-card color="#303030">
      <h3 class="font-weight-regular text-center ma-3">Add Previous Job Applications</h3>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="6">
                <div class="d-flex">
                  <CustomAutoComplete
                    v-model="previousJobApplicationsStore.selectedClient"
                    @input="
                      previousJobApplicationsStore.clientSearch = $event.target.value
                    "
                    hide-details="auto"
                    :items="previousJobApplicationsStore.clients"
                    item-value="id"
                    item-title="name"
                    return-object
                    label="Client"
                    class="me-3"
                  />
                  <v-btn
                    size="x-small"
                    class="align-self-center"
                    variants="tonal"
                    color="#b2d250"
                    icon="mdi-plus"
                    @click="clientStore.addClientDialog = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6">
                {{ previousJobApplicationsStore.selectedClient?.name }}</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="12" v-if="previousJobApplicationsStore.selectedJob">
            <v-table density="compact" theme="dark">
              <thead>
                <tr>
                  <td>Title</td>
                  <td align="center">Notice Period</td>
                  <td align="center">Salary</td>
                  <td align="center">Min Exp</td>
                  <td align="center">Max Exp</td>
                  <td align="center">Open</td>
                  <td align="center">Senior</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <h4 class="font-weight-regular">
                        {{ previousJobApplicationsStore.selectedJob?.title }}
                      </h4>
                      <p class="text-orange">
                        {{ previousJobApplicationsStore.selectedJob?.jobCategory }}
                      </p>
                    </div>
                  </td>
                  <td align="center">
                    {{ previousJobApplicationsStore.selectedJob?.noticePeriod }}
                    Days
                  </td>
                  <td align="center">
                    <div class="d-flex justify-center">
                      <span class="me-2">&#8377;</span>
                      <p>
                        {{
                          previousJobApplicationsStore.selectedJob?.salary?.toLocaleString(
                            "en-IN"
                          )
                        }}
                      </p>
                    </div>
                  </td>
                  <td align="center">
                    {{ previousJobApplicationsStore.selectedJob?.minExperience }}
                    years
                  </td>
                  <td align="center">
                    {{ previousJobApplicationsStore.selectedJob?.maxExperience }}
                    years
                  </td>

                  <td align="center">
                    <v-chip rounded="lg" class="w-100 d-flex justify-center">{{
                      previousJobApplicationsStore.selectedJob?.status
                    }}</v-chip>
                  </td>
                  <td align="center">
                    {{ previousJobApplicationsStore.selectedJob?.createdBy }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
          <v-col
            cols="6"
            v-if="
              previousJobApplicationsStore.users.length > 0 &&
              previousJobApplicationsStore.selectedJob
            "
          >
            <CustomAutoComplete
              v-model="previousJobApplicationsStore.selectedRecruiter"
              hide-details="auto"
              :items="previousJobApplicationsStore.users"
              item-value="id"
              item-title="fullName"
              return-object
              label="Recruiter"
            />
          </v-col>
          <v-col
            cols="6"
            v-if="
              previousJobApplicationsStore.users.length > 0 &&
              previousJobApplicationsStore.selectedJob
            "
          >
            <div class="d-flex">
              <CustomAutoComplete
                v-model="previousJobApplicationsStore.selectedSenior"
                hide-details="auto"
                :items="previousJobApplicationsStore.users"
                item-value="id"
                item-title="fullName"
                return-object
                label="Senior"
                class="me-3"
              />
              <v-btn
                size="x-small"
                class="align-self-center"
                variants="tonal"
                color="#b2d250"
                icon="mdi-plus"
                @click="previousJobApplicationsStore.addUserDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" v-if="previousJobApplicationsStore.selectedSenior">
            <v-row dense>
              <v-col cols="4">
                <v-btn
                  size="small"
                  class="align-self-center"
                  variants="tonal"
                  color="#b2d250"
                  @click="previousJobApplicationsStore.candidateDialog = true"
                >
                  Candidate
                </v-btn>
              </v-col>
              <v-col cols="8" v-if="previousJobApplicationsStore.selectedCandidate">
                <v-card color="transparent" border theme="dark">
                  <v-card-text class="pa-2">
                    <h3
                      class="font-weight-regular align-self-center"
                      style="cursor: pointer"
                    >
                      {{ previousJobApplicationsStore.selectedCandidate?.name }}
                    </h3>
                    <div class="pa-0 d-flex">
                      <div class="me-3">
                        <v-list class="pa-0">
                          <v-list-item
                            v-for="number in previousJobApplicationsStore
                              .selectedCandidate.phoneNumbers"
                            :key="number"
                            class="pb-1"
                            density="comapact"
                          >
                            +91 {{ number }}
                          </v-list-item>
                        </v-list>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="me-3">
                        <v-list class="pa-0">
                          <v-list-item
                            v-for="email in previousJobApplicationsStore.selectedCandidate
                              .emails"
                            :key="email"
                            class="pb-1"
                            density="comapact"
                          >
                            {{ email }}
                          </v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-if="previousJobApplicationsStore.selectedCandidate"
            ref="refData"
          >
            <v-row dense>
              <v-col cols="3">
                <v-menu v-model="menu" :close-on-content-click="false" class="">
                  <template v-slot:activator="{ props }">
                    <CustomTextFieldComponent
                      :rules="[requiredValidator]"
                      v-model="convertDate"
                      label="Date"
                      append-icon="event"
                      class="w-100"
                      clearable
                      prepend-inner-icon="mdi-calendar-month-outline"
                      v-bind="props"
                    />
                  </template>
                  <DatePicker
                    v-model="previousJobApplicationsStore.date"
                    borderless
                    mode="date"
                    @dayclick="menu = false"
                    class="bg-grey-darken-4 rounded-xl w-100"
                    title-position="left"
                    color="green"
                    is-dark="{ selector: ':root', darkClass: 'dark' }"
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <CustomTextFieldComponent
                  v-model="previousJobApplicationsStore.salary"
                  type="text"
                  inputmode="numeric"
                  @input="$event.target.value = $event.target.value.slice(0, 10)"
                  v-maska:[vMaskOptions]
                  :maxlength="10"
                  :rules="[requiredValidator, lengthOfSsalary]"
                  label="Salary"
                />
              </v-col>
              <v-col cols="3">
                <CustomSelectComponent
                  v-model="previousJobApplicationsStore.placementStatus"
                  label="Placement status"
                  :rules="[requiredValidator]"
                  :items="['Joined', 'Withdrawn']"
                ></CustomSelectComponent>
              </v-col>
              <v-col cols="3">
                <CustomSelectComponent
                  v-model="previousJobApplicationsStore.closureStatus"
                  label="Closure status"
                  :rules="[requiredValidator]"
                  :items="['Confirmed', 'Withdrawn']"
                ></CustomSelectComponent>
              </v-col>
              <v-col cols="3">
                <CustomSelectComponent
                  v-model="previousJobApplicationsStore.feeType"
                  :items="['Fixed', 'Percentage']"
                  :rules="[requiredValidator]"
                  label="Fee type"
                />
              </v-col>
              <v-col
                cols="3"
                v-if="previousJobApplicationsStore.feeType === 'Percentage'"
              >
                <CustomTextFieldComponent
                  v-model.number="previousJobApplicationsStore.percentage"
                  type="number"
                  :rules="[requiredValidator]"
                  label="Percentage"
                  active="#B2D250"
                ></CustomTextFieldComponent>
              </v-col>
              <v-col cols="3" v-if="previousJobApplicationsStore.feeType === 'Fixed'">
                <CustomTextFieldComponent
                  v-model.number="previousJobApplicationsStore.amount"
                  type="number"
                  :rules="[requiredValidator]"
                  label="Amount"
                  active="#B2D250"
                ></CustomTextFieldComponent>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="justify-center gap-3">
        <v-btn variant="tonal" color="#74a942" @click="onSubmit()"> <p>Submit</p></v-btn>
        <v-btn variant="tonal" color="red" @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <AddClientDrawer />
  <AddUserDialog />
  <JobsList />
  <CandidateSearchDialog />

  <ConfirmationDialoge
    v-model="confirmationDialog"
    @action-yes="onConfirm()"
    @update-model="confirmationDialog = false"
    title="Add job application"
    text="Want to add
    job application ?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
// import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import AddClientDrawer from "@/components/Client/drawers/AddClientDrawer.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import AddUserDialog from "@/components/SharedComponent/PreviousJobApplications/dialogs/AddUserDialog.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import CustomAutoComplete from "@/components/SharedComponent/CustomAutoComplete.vue";
import JobsList from "@/components/SharedComponent/PreviousJobApplications/dialogs/JobsList.vue";
import CandidateSearchDialog from "@/components/SharedComponent/PreviousJobApplications/dialogs/CandidateSearchDialog.vue";
import { usePreviousJobApplicationsStore } from "@/store/AddPreviousJobApplications";
import { ref, watch } from "vue";
import { computed } from "vue";
import { DatePicker } from "v-calendar";
import { vMaska } from "maska";
import { getDDMMYYYYFormat } from "@/composables/DateTime";
import { useClientStore } from "@/store/Client/ClientStore";
const clientStore = useClientStore();
const dialog = ref(false);
const confirmationDialog = ref(false);
const menu = ref(false);
const previousJobApplicationsStore = usePreviousJobApplicationsStore();

const onSubmit = () => {
  if (previousJobApplicationsStore.feeType) {
    confirmationDialog.value = true;
  }
};
const onConfirm = () => {
  confirmationDialog.value = false;
  dialog.value = false;
  previousJobApplicationsStore.addJobApplication();
};
const vMaskOptions = {
  mask: "99,99,99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};
const convertDate = computed(() => {
  return getDDMMYYYYFormat(previousJobApplicationsStore.date) ===
    "Invalid previousJobApplicationsStore.date" ||
    getDDMMYYYYFormat(previousJobApplicationsStore.date) === "01/01/1970"
    ? ""
    : getDDMMYYYYFormat(previousJobApplicationsStore.date);
});
watch(
  () => dialog.value,
  () => {
    if (dialog.value) {
      previousJobApplicationsStore.getUsers();
    }
  }
);
watch(
  () => previousJobApplicationsStore.clientSearch,
  () => {
    previousJobApplicationsStore.onClientSearch();
  }
);
watch(
  () => previousJobApplicationsStore.selectedClient,
  () => {
    if (previousJobApplicationsStore.selectedClient) {
      previousJobApplicationsStore.jobListDialog = true;
    }
  }
);
watch(
  () => previousJobApplicationsStore.placementStatus,
  () => {
    if (previousJobApplicationsStore.placementStatus === "Joined") {
      previousJobApplicationsStore.closureStatus = "Confirmed";
    } else if (previousJobApplicationsStore.placementStatus === "Withdrawn") {
      previousJobApplicationsStore.closureStatus = "Withdrawn";
    }
  }
);
</script>
