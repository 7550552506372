import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAG9WzlWXAOYN6NtycW_oVB8hKUxfPhiwM",
  authDomain: "genxhire-server.firebaseapp.com",
  projectId: "genxhire-server",
  storageBucket: "genxhire-server.appspot.com",
  messagingSenderId: "782372641243",
  appId: "1:782372641243:web:6bef5a954bcb9a2f60effe",
  measurementId: "G-RX57FZTMYH",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
