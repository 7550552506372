<template>
  <v-overlay persistent class="align-center justify-center">
    <div class="">
      <div class="d-flex justify-center align-center">
        <v-progress-circular
          :size="50"
          
          :width="4"
          color="#b2d250"
          indeterminate
        >
        </v-progress-circular>
      </div>

      <p class="text-white text-h6">Please Wait</p>
    </div>
  </v-overlay>
</template>
