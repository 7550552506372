<template>
  <v-overlay v-model="authStore.notificationAlert" persistent>
    <v-card theme="dark" color="#b2d250">
      <v-card-text>
        <div>
          You have blocked Notifications.<v-icon size="small"
            >mdi-bell-outline</v-icon
          >
          To use this, change your Notification settings in browser.
        </div>

        <!-- <div class="d-flex justify-center">
          <v-btn
            variant="outlined"
            size="small"
            @click="settingPage"
            class="mt-3"
            >Setting
          </v-btn>
        </div> -->
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script setup>
import { useAuthStore } from "@/store/AuthStore";
const authStore = useAuthStore();

// const settingPage = () => {
//   window.open('');
// };
</script>

<style lang="scss" scoped>
.v-overlay {
  margin-top: 10vh;
  display: flex;
  justify-content: center;
}
</style>
