// permissionDirective.js
import { ref, } from "vue";
import VueCookies from "vue-cookies";

export const usePermissionDirective = {
  mounted: (el, binding) => {
    const hasPermission = ref(false);

    const userPermissions = VueCookies.get("user").permissions;
    const userRole = VueCookies.get("user").role;

    const checkPermission = () => {
      const requiredPermissions = binding.value;

      const isSuperAdmin = userRole.includes("SUPER_ADMIN") ||  userRole.includes("SUPER_USER");
      if (isSuperAdmin) {
        return true;
      } else {
        if (userPermissions.includes(requiredPermissions)) {
          hasPermission.value = userPermissions.includes(requiredPermissions);
        } else {
          el.style.display = "none";
        }
      }
    };
    checkPermission();
  },
};
