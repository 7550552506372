<template>
  <v-dialog v-model="dialog" activator="parent" width="350">
    <v-card color="#303030" rounded="lg" max-height="400">
      <v-card-text class="pb-0">
        <h3 class="font-weight-regular text-center mb-4">{{ title }}</h3>
        <DialogCloseBtn @close-dialog="dialog = false" />

        <v-form ref="refForm">
          <v-row no-gutters>
            <v-col cols="12" v-if="clientId">
              <CustomSelectComponent
                label="Hiring Manager"
                v-model="selectedContact"
                :items="getClientContact.map((contact) => contact.name)"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                variant="outlined"
                color="#b2d250"
                @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                hide-details="auto"
                :label="`Remark ${option ? '(Optional)' : ''}`"
                :rules="option ? [] : [requiredValidator, commentLengthValidator]"
                v-model="comment"
                counter="30"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn variant="tonal" color="#74a942" @click="onSubmit()">Submit </v-btn>
        <v-btn variant="tonal" color="red" class="me-2" @click="closeDialog()"
          >Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onConfirm()"
    @update-model="confirmDialogStage = false"
    :title="title"
    :text="`Are you sure you want to ${title}?`"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import { useClientStore } from "@/store/Client/ClientStore";
import { ref, defineProps, defineEmits, onMounted, computed, toRaw } from "vue";
import { toast } from "vue3-toastify";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { requiredValidator } from "@/composables/Validators";
import { commentLengthValidator } from "@/composables/Validators";
const jobApplicationStore = useJobApplicationStore();
const clientStore = useClientStore();
const props = defineProps(["dialog", "title", "clientId", "option"]);
const emits = defineEmits([
  "close-dialog",
  "pass-interview",
  "fail-interview",
  "cancel-interview",
  "complete-interview",
  "update-interview",
  "approve-jobApplication",
  "reject-jobApplication",
  "Hold-Job",
  "close-Job",
  "remove-job",
  "screening-reject",
  "perform-rollback",
  "reject-leave",
  "cancel-leave",
  "reject-closure",
]);
const confirmDialogStage = ref(false);
const comment = ref("");
const dialog = ref(false);
const selectedContact = ref("");
const refForm = ref();
// const interviewStore = useInterviewStore();

const getClientContact = computed(() => {
  const contacts = clientStore.clientContacts.map((contact) => ({
    id: contact.id,
    name: contact.name,
  }));
  return [...new Set(contacts)];
});

const hiringManagerId = computed(() => {
  return getClientContact.value.find((contact) => contact.name === selectedContact.value)
    ?.id;
});

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const onConfirm = () => {
  let data = {
    comment: comment.value,
  };
  if (props.option) {
    emits("approve-jobApplication", {
      ...data,
      hiringManagerId: hiringManagerId.value,
    });
    confirmDialogStage.value = false;
    closeDialog();
  } else {
    if (comment.value) {
      emits("pass-interview", data);
      emits("fail-interview", data);
      emits("cancel-interview", data);
      emits("complete-interview", data);
      emits("update-interview", data);
      emits("Hold-Job", data);
      emits("close-job", data);
      emits("remove-job", data);
      emits("reject-jobApplication", data);
      emits("perform-rollback", data);
      emits("reject-offer", data);
      emits("screening-reject", data);
      emits("reject-leave", data);
      emits("cancel-leave", data);
      emits("reject-closure", data);
      emits("confirm-closure", data);
      emits("withdrawn-closure", data);

      confirmDialogStage.value = false;
      closeDialog();
    } else {
      toast.error("Details Required", {
        autoClose: 1500,
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        transition: toast.TRANSITIONS.ZOOM,
      });
    }
  }
};
const closeDialog = () => {
  dialog.value = false;
};
onMounted(async () => {
  if (props.clientId) {
    await clientStore.getClientContactPersons(props.clientId);
  }
  const data = clientStore.clientContacts.find(
    (contact) => contact.id === jobApplicationStore.jobApplication?.job.hiringManager.id
  );
  const rawData = data ? toRaw(data) : null;
  selectedContact.value = rawData ? rawData.name : "";
});
</script>
