<template>
  <v-dialog v-model="previousJobApplicationsStore.addJobDialog" max-width="400">
    <v-card color="#303030">
      <v-card-text>
        <!-- 👉 Title -->
        <h3 class="font-weight-regular text-center mb-4">Add Job</h3>
        <DialogCloseBtn @close-dialog="closeForm()" />

        <!--  Form -->
        <v-form ref="refForm">
          <v-row dense>
            <!--   name -->
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="title"
                :rules="[requiredValidator]"
                @input="title = $event.target.value.replace(/\s+/g, ' ')"
                label="Title"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center gap-3">
        <v-btn variant="tonal" color="#74a942" @click="onSubmit()"> <p>Submit</p></v-btn>
        <v-btn variant="tonal" color="red" @click="closeForm()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmationDialog"
    @action-yes="onConfirm()"
    @update-model="confirmationDialog = false"
    title="Add job"
    text="Want to add
    job ?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import { ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { usePreviousJobApplicationsStore } from "@/store/AddPreviousJobApplications";
const confirmationDialog = ref(false);
const previousJobApplicationsStore = usePreviousJobApplicationsStore();
const refForm = ref();
const title = ref("");
const closeForm = () => {
  title.value = "";
  previousJobApplicationsStore.addJobDialog = false;
};

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmationDialog.value = true;
    }
  });
};

const onConfirm = () => {
  let data = {
    title: title.value,
  };
  confirmationDialog.value = false;
  previousJobApplicationsStore.addJobDialog = false;
  previousJobApplicationsStore.addJob(data);
};
</script>
