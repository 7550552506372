<template>
  <v-dialog v-model="jobApplicationStore.viewOrUpdateRsumeDialog" max-width="500">
    <v-card theme="dark">
      <DialogCloseBtn
        @close-dialog="jobApplicationStore.viewOrUpdateRsumeDialog = false"
      />
      <v-container fluid class="pa-2">
        <v-row dense>
          <v-col cols="6">
            <v-card
              color="#303030"
              style="cursor: pointer"
              @click="jobApplicationStore.updateCV()"
            >
              <v-card-text>
                <div class="">
                  <v-img :src="updateResumeIMG" class="mx-auto" :width="40" />
                </div>
                <h4 class="font-weight-regular text-center mt-2">
                  {{
                    jobApplicationStore.jobApplication.resumeUpdated
                      ? "Re-Upload CV"
                      : "Update CV"
                  }}
                </h4>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              color="#303030"
              style="cursor: pointer"
              :disabled="!jobApplicationStore.jobApplication.resumeUpdated"
              @click="
                jobApplicationStore.jobApplication.resumeUpdated
                  ? jobApplicationStore.viewCV()
                  : ''
              "
            >
              <v-card-text>
                <div class="">
                  <v-img :src="viewResume" class="mx-auto" :width="40" />
                </div>
                <h4 class="font-weight-regular text-center mt-2">View CV</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
  <UpdateResume />
</template>

<script setup>
import UpdateResume from "@/components/JobAplication/dialogs/UpdateResume.vue";
import updateResumeIMG from "@/assets/icons/updateResume.png";
import viewResume from "@/assets/icons/viewResume.png";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
const jobApplicationStore = useJobApplicationStore();
</script>
