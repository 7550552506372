<template>
  <v-dialog v-model="dialog" activator="parent" max-width="500">
    <v-card>
      <DialogCloseBtn @close-dialog="dialog = false" />
      <v-container fluid class="pa-2">
        <h3 class="font-weight-regular text-center pb-3">Update Job Application</h3>

        <v-row dense>
          <v-col cols="6">
            <v-card color="#303030" style="cursor: pointer">
              <v-card-text>
                <div class="">
                  <v-img :src="calendar" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">Update Date</h3>
              </v-card-text>
              <UpdateJobApplicationDate />
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card color="#303030" style="cursor: pointer">
              <v-card-text>
                <div class="">
                  <v-img :src="interview" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">Update Interview</h3>
              </v-card-text>
            </v-card>
            <CommentDialog
              title="Update Interview"
              @close="active = false"
              @update-interview="interviewStore.updateCompletedInterview"
            />
          </v-col>
          <v-col cols="6">
            <v-card color="#303030" style="cursor: pointer">
              <v-card-text>
                <div class="">
                  <v-img :src="calendar" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">Create Closure</h3>
              </v-card-text>
              <CreateClosure />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup>
import CommentDialog from "@/components/JobAplication/dialogs/CommentDialog.vue";
import UpdateJobApplicationDate from "@/components/JobAplication/dialogs/UpdateJobApplicationDate.vue";
import CreateClosure from "@/components/JobAplication/dialogs/CreateClosure.vue";

import { ref } from "vue";
import calendar from "@/assets/icons/calendar.png";
import interview from "@/assets/icons/interview.png";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import { useInterviewStore } from "@/store/JobApplications/InterviewStore";
const interviewStore = useInterviewStore();
const dialog = ref(false);
</script>
