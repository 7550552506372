import api from "@/services/api";
import { defineStore } from "pinia";
import { getYYYYMMDDFormat } from "@/composables/DateTime";
import { toast } from "vue3-toastify";
// import VueCookies from "vue-cookies";

export const usePreviousJobApplicationsStore = defineStore(
  "previousJobApplicationsStore",
  {
    state: () => ({
      isLoading: false,
      jobListDialog: false,
      candidateDialog: false,
      addCandidateDialog: false,
      addJobDialog: false,
      addUserDialog: false,
      clientSearch: "",
      clients: [],
      searchedCandidates: [],
      selectedJob: null,
      selectedClient: null,
      selectedRecruiter: null,
      selectedSenior: null,
      selectedCandidate: null,
      date: null,
      salary: null,
      placementStatus: null,
      closureStatus: null,
      feeType: null,
      percentage: null,
      amount: null,
      jobSearch: "",
      jobs: [],
      users: [],
    }),
    actions: {
      async onClientSearch() {
        this.isLoading = true;
        await api
          .get(`/clients/search?query=${this.clientSearch}`)
          .then((res) => {
            this.clients = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },
      async jobList() {
        this.isLoading = true;
        await api
          .get(`/clients/${this.selectedClient?.id}/jobs`)
          .then((res) => {
            this.jobs = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },
      async getUsers() {
        this.isLoading = true;
        await api
          .get(`/users/previous-job-application`)
          .then((res) => {
            this.users = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },
      async addJob(data) {
        this.isLoading = true;
        await api
          .post(`/clients/${this.selectedClient?.id}/job/previous`, data)
          .then((res) => {
            toast.success(res.data.message, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },

      async addUser(data) {
        this.isLoading = true;
        await api
          .post(`/users/previous`, data)
          .then((res) => {
            toast.success(res.data.message, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },

      async addJobApplication() {
        this.isLoading = true;
        await api
          .post(`/previous-job-applications`, {
            jobId: this.selectedJob?.id,
            recruiterId: this.selectedRecruiter?.id,
            seniorId: this.selectedSenior?.id,
            candidateId: this.selectedCandidate?.id,
            date: getYYYYMMDDFormat(this.date),
            salary: Number(this.salary?.split(",").join("")),
            placementStatus: this.placementStatus,
            closureStatus: this.closureStatus,
            feeType: this.feeType,
            percentage: this.percentage ? this.percentage : 0,
            amount: this.amount ? this.amount : 0,
          })
          .then((res) => {
            toast.success(res.data.message, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },
      async searchCandidate(search) {
        this.isLoading = true;
        await api
          .get(`/candidates/search/v1/${search}`)
          .then((res) => {
            this.searchedCandidates = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },
      selectJob(item) {
        this.selectedJob = item;
        this.jobListDialog = false;
      },
      selectCandidate(item) {
        this.selectedCandidate = item;
        this.candidateDialog = false;
      },
    },
  }
);
