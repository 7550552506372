<template>
  <v-btn
    icon="mdi-refresh"
    color="#b2d250"
    :size="size"
    class="me-3 align-self-center"
    @click="onRefresh()"
  >
    <v-icon>mdi-refresh</v-icon>
    <v-tooltip theme="dark" :location="tooltipLocation" activator="parent"
      >Refresh</v-tooltip
    >
  </v-btn>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps(["size", "tooltipLocation"]);
const emits = defineEmits(["on-refresh"]);
const size = ref(props.size ? props.size : "small");
const tooltipLocation = ref(
  props.tooltipLocation ? props.tooltipLocation : "bottom"
);
const onRefresh = () => {
  emits("on-refresh");
};
</script>
