<template>
  <v-dialog v-model="dialog" activator="parent" max-width="400">
    <v-card color="#303030">
      <v-card-text>
        <h3 class="font-weight-regular text-center">Update Candidate</h3>
        <v-avatar
          style="
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            z-index: 10;
          "
          variant="tonal"
          size="small"
          class="ma-1 elevation-16 closeBtn"
          @click="dialog = false"
          rounded="lg"
        >
          <v-icon size="x-small">mdi-close</v-icon>
        </v-avatar>
        <v-row class="mt-3" no-gutters>
          <v-col cols="12">
            <CustomTextFieldComponent v-model="firstName" label="First Name" />
          </v-col>
          <v-col cols="12">
            <CustomTextFieldComponent v-model="lastName" label="Last Name" />
          </v-col>
          <v-col cols="12">
            <CustomSelectComponent
              v-model="gender"
              label="Gender"
              :items="['Male', 'Female']"
            ></CustomSelectComponent>
          </v-col>
          <v-col cols="12">
            <CustomSelectComponent
              v-model="profession"
              label="Profession"
              :items="corporateProfessions"
            ></CustomSelectComponent>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn variant="tonal" color="green" @click="confirmDialogJob = true"
          >Update</v-btn
        >
        <v-btn variant="tonal" color="red" @click="dialog = false"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <LoaderDialog :model-value="jobStore.isLoading" />
  <ConfirmationDialoge
    v-model="confirmDialogJob"
    @action-yes="onUpdate()"
    @update-model="confirmDialogJob = false"
    title="Update Candidate"
    text="Are you sure you want to Update Candidate?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import { ref, defineProps } from "vue";
import { corporateProfessions } from "@/composables/CandidateProfession";
import { useJobStore } from "@/store/Job/JobStore";
import { useCandidateStore } from "@/store/Candidate/CandidateStore";
const candidateStore = useCandidateStore();
const props = defineProps(["item"]);
const jobStore = useJobStore();
const dialog = ref(false);
const confirmDialogJob = ref(false);
const firstName = ref(props.item?.fullName.split(" ")[0]);
const lastName = ref(props.item?.fullName.split(" ")[1]);
const gender = ref(props.item?.gender);
const profession = ref(props.item?.profession);

const onUpdate = async () => {
  let candidate = {
    firstName: firstName.value,
    lastName: lastName.value,
    gender: gender.value,
    profession: profession.value,
  };
  confirmDialogJob.value = false;
  dialog.value = false;
  await candidateStore.updateCandidate(props.item.id, candidate);
};


</script>

<style scoped>
.closeBtn:hover {
  transform: scale(1.1);
}
.close-button {
  border: 2px solid grey;
  border-style: dashed;
}
</style>
