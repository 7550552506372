import { defineStore } from "pinia";
import api from "@/services/api";
import { getAllDaysInMonth } from "@/composables/DateTime";

export const useUserAttendanceStore = defineStore("userAttendanceStore", {
  state: () => ({
    userAttendance: [],
    isLoading: false,
    regularizedAttendanceDialog: false,
    timePickerDialog: false,
    selectedAttendance: "",
    time: null,
    month: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
  }),
  actions: {
    async getAttendance(userId) {
      this.isLoading = true;
      await api
        .get(
          `/attendance/user/${userId}/month/${this.month.month + 1}/year/${
            this.month.year
          }/admin`
        )
        .then((res) => {
          this.userAttendance = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    onDateRangeChanged(userId, month) {
      this.month = month;
      this.getAttendance(userId);
    },
  },
  getters: {
    getUserAttendanceData() {
      const today = new Date();
      const datesOfMonth = getAllDaysInMonth(this.month.year, this.month.month);
      const formattedAttendanceData = {};

      // Initialize the formattedAttendanceData object with absent data for all dates
      datesOfMonth.forEach((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        // const formattedDate = date.toISOString();
        if (date <= today) {
          formattedAttendanceData[formattedDate] = {
            date: formattedDate,
            punchInTime: null,
            punchInLocation: [null, null],
            punchOutTime: null,
            punchOutLocation: [null, null],
            status: "Absent",
            seniorApproved: false,
            adminApproved: false,
          };
        }
      });

      // Update formattedAttendanceData with actual attendance data
      this.userAttendance.forEach((attendance) => {
        const formattedDate = new Date(attendance.date).toISOString();
        if (new Date(attendance.date) <= today) {
          formattedAttendanceData[formattedDate] = {
            date: formattedDate,
            punchInTime: attendance.inTime || null,
            punchInLocation: [
              attendance.inLocation ? attendance.inLocation.latitude : null,
              attendance.inLocation ? attendance.inLocation.longitude : null,
            ],
            punchOutTime: attendance.outTime || null,
            punchOutLocation: [
              attendance.outLocation ? attendance.outLocation.latitude : null,
              attendance.outLocation ? attendance.outLocation.longitude : null,
            ],
            status: attendance.status || "Absent",
            seniorApproved: attendance.seniorApproved || false,
            hrApproved: attendance.hrApproved || false,
          };
        }
      });
      const formattedAttendanceDataByDate = Object.values(
        formattedAttendanceData
      );

      return formattedAttendanceDataByDate;
    },
  },
});

export const useUserProfileStore = defineStore("userProfileStore", {
  state: () => ({
    updateUserForm: false,
    resetPassword: false,
    success: false,
    activatDeactivateUser: false,
  }),
  actions: {
  
  },
});
