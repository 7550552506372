<template>
  <v-dialog
    v-model="jobApplicationStore.viewOrUpdateAcknowledgmentDialog"
    max-width="500"
  >
    <v-card theme="dark">
      <DialogCloseBtn
        @close-dialog="jobApplicationStore.viewOrUpdateAcknowledgmentDialog = false"
      />
      <v-container fluid class="pa-2">
        <v-row dense>
          <v-col cols="6">
            <v-card
              color="#303030"
              style="cursor: pointer"
              @click="jobApplicationStore.updateAckngm()"
            >
              <v-card-text>
                <div class="">
                  <v-img :src="updateAcknowledgment" class="mx-auto" :width="40" />
                </div>
                <h4 class="font-weight-regular text-center mt-2">
                  {{
                    jobApplicationStore.jobApplication.acknowledged
                      ? "Re-Upload Acknowledgment"
                      : "Update Acknowledgment"
                  }}
                </h4>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              color="#303030"
              style="cursor: pointer"
              :disabled="!jobApplicationStore.jobApplication.acknowledged"
              @click="
                jobApplicationStore.jobApplication.acknowledged
                  ? jobApplicationStore.viewAcknowledgment()
                  : ''
              "
            >
              <v-card-text>
                <div class="">
                  <v-img :src="viewAcknowlegmnet" class="mx-auto" :width="40" />
                </div>
                <h4 class="font-weight-regular text-center mt-2">View Acknowledgment</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
  <UpdateAcknowledgement />
</template>

<script setup>
import UpdateAcknowledgement from "@/components/JobAplication/dialogs/UpdateAcknowledgement.vue";
import updateAcknowledgment from "@/assets/icons/updateAcknowledgment.png";
import viewAcknowlegmnet from "@/assets/icons/viewAcknowlegmnet.png";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
const jobApplicationStore = useJobApplicationStore();
</script>
