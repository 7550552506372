<template>
  <v-dialog v-model="previousJobApplicationsStore.jobListDialog" max-width="140vh">
    <v-card
      theme="dark"
      height="700"
      rounded="lg"
      elevation="9"
      class="d-flex flex-column"
    >
      <DialogCloseBtn
        @close-dialog="previousJobApplicationsStore.jobListDialog = false"
      />

      <div
        class="d-flex justify-center mt-5 w-50 flex-0-1"
        ref="refSearch"
        style="position: relative; top: 0; left: 0; transform: translate(50%, 0%)"
      >
        <CustomTextFieldComponent
          class="me-3 text-grey-lighten-1"
          v-model="search"
          label="Search"
          hideDetails="auto"
        ></CustomTextFieldComponent>
        <CustomRefreshButton @on-refresh="previousJobApplicationsStore.jobList()" />

        <v-btn
          icon="mdi-plus"
          color="#b2d250"
          size="small"
          @click="previousJobApplicationsStore.addJobDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="flex-1-1" ref="refLoader">
        <div v-if="refLoader">
          <div
            v-if="previousJobApplicationsStore.isLoading"
            class="mx-auto text-center text-white text-h5s"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <v-progress-circular
              indeterminate
              color="#b2d250"
              size="large"
              class="text-center"
            ></v-progress-circular>
            <h3 class="font-weight-regular mt-3 text-center">Please Wait...</h3>
          </div>
          <v-data-table-virtual
            :headers="headers"
            fixed-header
            v-else
            :items="filteredJobs"
            :height="`height : ${refLoader.clientHeight}px`"
          >
            <template v-slot:[`item`]="{ item }">
              <tr @click="previousJobApplicationsStore.selectJob(item)">
                <td>
                  <div>
                    <h4 class="font-weight-regular">{{ item.title }}</h4>
                    <p class="text-orange">{{ item.jobCategory }}</p>
                  </div>
                </td>
                <td align="center">{{ item.noticePeriod }} Days</td>
                <td align="center">
                  <div class="d-flex justify-center">
                    <span class="me-2">&#8377;</span>
                    <p>{{ item.salary?.toLocaleString("en-IN") }}</p>
                  </div>
                </td>
                <td align="center">{{ item.minExperience }} years</td>
                <td align="center">{{ item.maxExperience }} years</td>

                <td align="center">
                  <v-chip
                    :color="getJobStatusColor(item.status)"
                    rounded="lg"
                    class="w-100 d-flex justify-center"
                    >{{ item.status }}</v-chip
                  >
                </td>
                <td align="center">
                  {{ item?.createdBy }}
                </td>
              </tr>
            </template>
          </v-data-table-virtual>
        </div>
      </div>
    </v-card>
  </v-dialog>
  <AddJobDialog />
</template>
<script setup>
import AddJobDialog from "@/components/SharedComponent/PreviousJobApplications/dialogs/AddJobDialog.vue";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import CustomRefreshButton from "@/components/SharedComponent/CustomRefreshButton.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import { computed, reactive, ref, watch } from "vue";
import { usePreviousJobApplicationsStore } from "@/store/AddPreviousJobApplications";
import { getJobStatusColor } from "@/composables/Formatters";
const previousJobApplicationsStore = usePreviousJobApplicationsStore();

const search = ref("");
const refLoader = ref();
const refSearch = ref();
const filteredJobs = computed(() => {
  return previousJobApplicationsStore.jobs.filter((job) => {
    return job.title.toLowerCase().includes(search.value.toLowerCase());
  });
});
watch(
  () => previousJobApplicationsStore.jobListDialog,
  () => {
    if (previousJobApplicationsStore.jobListDialog) {
      previousJobApplicationsStore.jobList();
    }
  }
);
const headers = reactive([
  {
    title: "Title",
    align: "start",
    key: "title",
    sortable: false,
  },
  {
    title: "Notice Period",
    align: "center",
    key: "noticePeriod",
    sortable: false,
  },
  { title: "Salary", align: "center", key: "salary", sortable: false },
  {
    title: "Min Exp",
    align: "center",
    key: "minExperience",
    sortable: false,
  },
  {
    title: "Max Exp",
    align: "center",
    key: "maxExperience",
    sortable: false,
  },

  {
    title: "Status",
    align: "center",
    key: "status",
    sortable: false,
  },
  {
    title: "Senior",
    align: "center",
    key: "allocations",
    sortable: false,
  },
]);
</script>
