<template>
  <div>
    <SideBar />
    <v-toolbar color="#3d3d3d" elevation="8">
      <v-tabs
        v-model="teamStore.userTab"
        class="text-grey-lighten-1"
        color="#B6D230"
      >
        <v-tab>
          <v-icon :size="18" icon="mdi-view-dashboard-outline" class="me-1" />
          <span>Overview</span>
        </v-tab>
        <v-tab>
          <v-icon :size="18" icon="mdi-application" class="me-1" />
          <span>Job Applications</span>
        </v-tab>
        <v-tab v-permission="'READ_TEAM_SETTING'">
          <v-icon :size="18" icon="mdi-cog" class="me-1" />
          <span>Setting</span>
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <slot />
  </div>
</template>

<script setup>
import { useTeamStore } from "@/store/Team/TeamStore";
import SideBar from "@/components/SideNavigation/SideBar.vue";
const teamStore = useTeamStore();
</script>
