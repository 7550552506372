<template>
  <v-card color="#343435" height="71vh" class="overflow-y-auto">
    <v-card-title class="font-weight-regular">History</v-card-title>
    <v-timeline side="end" class="justify-start">
      <v-timeline-item
        :size="12"
        elevation="16"
        v-for="history in formattedHistory"
        :key="history"
      >
        <div>
          <div>
            <h4 class="font-weight-regular align-self-center">
              {{ history.title }}
            </h4>
            <h5 class="font-weight-regular align-self-center">
              {{ history.description }}
            </h5>
          </div>
          <div>
            <h5
              class="font-weight-regular align-self-center text-grey-lighten-2"
            >
              {{ getMonthDateYearTimeFormat(history.createdOn) }}
            </h5>
            <h5 class="font-weight-regular me-3 align-self-center">
              {{ history.createdBy }}
            </h5>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script setup>
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { getMonthDateYearTimeFormat } from "@/composables/DateTime";
import { computed } from "vue";
const jobApplicationStore = useJobApplicationStore();

const formattedHistory = computed(() => {
  const reversedArray = jobApplicationStore.jobApplication.histories;
  return [...reversedArray].reverse();
});
</script>

<style scoped>
:deep(.v-timeline-item__body) {
  width: 100% !important;
}
:deep(.v-timeline--vertical.v-timeline) {
  height: 0% !important;
}
</style>
