<template>
  <v-overlay
    v-model="dashboardStore.searchDialog"
    :offset-y="false"
    transition="slide-x-transition"
    :offset-x="false"
    right
  >
    <v-card class="h-screen overflow-auto" theme="dark" width="80vh" color="#343435">
      <v-card-text>
        <DialogCloseBtn @close-dialog="dashboardStore.searchDialog = false" />
        <v-row>
          <v-col cols="12" lg="3" md="3" sm="3">
            <v-img
              :src="searchIcon"
              :width="100"
              :height="100"
              aspect-ratio="4/3"
              contain
            ></v-img
          ></v-col>
          <v-col cols="12" lg="9" md="9" sm="9">
            <h3 class="font-weight-regular">Search Candidate</h3>
            <v-form ref="refForm" @submit.prevent>
              <CustomTextFieldComponent
                class="mt-3"
                v-model="searchValue"
                :color="'orange-lighten-3'"
                density="default"
                placeholder="Search by Name, Number or Email"
                @keyup.prevent.enter="searchCandidate()"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card
          max-height="79vh"
          class="overflow-auto"
          v-if="candidateStore.searchedCandidates?.length > 0"
        >
          <v-card
            v-for="candidate in candidateStore.searchedCandidates"
            :key="candidate.id"
            color="transparent"
            border
          >
            <v-card-text class="pa-2">
              <v-btn
                icon="mdi-pencil"
                v-if="
                  user.role.includes('SUPER_ADMIN') || user.role.includes('SUPER_USER')
                "
                color="blue"
                style="position: absolute; top: 0; right: 0; cursor: pointer; z-index: 10"
                size="small"
                class="ma-2"
                variant="tonal"
              >
                <v-icon size="small">mdi-pencil</v-icon>
                <v-tooltip activator="parent" location="bottom">Edit Candidate</v-tooltip>
                <UpdateCandidate :item="candidate" />
              </v-btn>
              <div class="d-flex pb-0">
                <div class="me-3 align-self-center">
                  <v-img
                    :src="candidate.gender.toLowerCase() === 'male' ? male : female"
                    :width="60"
                    :height="60"
                  />
                </div>

                <h3 class="font-weight-regular align-self-center" style="cursor: pointer">
                  {{ candidate?.fullName }}
                </h3>
              </div>
              <div class="pa-0 d-flex">
                <div class="me-3">
                  <v-list class="pa-0">
                    <v-list-item
                      v-for="number in candidate.phoneNumbers"
                      :key="number"
                      class="pb-1"
                      density="comapact"
                    >
                      +91 {{ number }}
                    </v-list-item>
                  </v-list>
                </div>
                <v-divider vertical></v-divider>
                <div class="me-3">
                  <v-list class="pa-0">
                    <v-list-item
                      v-for="email in candidate.emails"
                      :key="email"
                      class="pb-1"
                      density="comapact"
                    >
                      {{ email }}
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-card-text>
            <v-card-actions v-if="candidate.jobApplications?.length > 0">
              <div class="d-flex">
                <h3 class="font-weight-regular ml-2 me-5">Job Applications</h3>
                <h3 class="font-weight-regular">
                  {{ candidate.jobApplications?.length }}
                </h3>
              </div>
              <v-spacer></v-spacer>

              <v-btn
                :icon="show[candidate.id] ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                @click="show[candidate.id] = !show[candidate.id]"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show[candidate.id]">
                <v-divider></v-divider>

                <v-card
                  border
                  color="#292929"
                  v-for="jobApplication in candidate.jobApplications"
                  :key="jobApplication.id"
                  @click="viewJobApplication(jobApplication.id)"
                >
                  <v-card-text>
                    <div class="d-flex justify-space-between">
                      <div>
                        <h3 class="font-weight-regular">
                          {{ jobApplication.jobTitle }}
                        </h3>
                        <h4 class="font-weight-regular mt-1">
                          {{ jobApplication.clientName }}
                        </h4>
                      </div>
                      <div>
                        <h4 class="font-weight-regular">
                          {{ getMonthDateYearTimeFormat(jobApplication.applicationDate) }}
                        </h4>
                        <h4 class="font-weight-regular mt-1 d-flex justify-end">
                          {{ jobApplication.jobStatus }}
                        </h4>
                      </div>
                    </div>

                    <div class="d-flex justify-space-between mt-7">
                      <div>
                        <h4 class="font-weight-regular">
                          {{ jobApplication.recruiterName }}
                        </h4>
                        <h4 class="font-weight-regular mt-1">Recruiter</h4>
                      </div>
                      <div>
                        <h4 class="font-weight-regular">
                          {{ jobApplication.seniorName }}
                        </h4>
                        <h4 class="font-weight-regular mt-1 d-flex justify-end">
                          Senior
                        </h4>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-expand-transition>
          </v-card>
        </v-card>
        <div v-else>
          <div
            style="
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
            "
          >
            <div>
              <div class="d-flex justify-center">
                <v-avatar size="65" icon="mdi-account" variant="tonal" rounded="lg">
                  <v-icon size="large">mdi-account</v-icon>
                </v-avatar>
              </div>

              <h2 class="font-weight-regular pa-2">Candidate Not Found</h2>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
  <v-overlay
    :transition="'slide-y-transition'"
    :offset-y="false"
    :offset-x="false"
    v-model="jobApplicationsStore.overlay"
    right
  >
    <JobApplicationDetails
  /></v-overlay>
  <LoaderDialog :model-value="candidateStore.isLoading" />
</template>

<script setup>
import JobApplicationDetails from "@/components/JobAplication/drawer/JobApplicationDetails.vue";
import LoaderDialog from "@/components/SharedComponent/dialogs/LoaderDialog.vue";
import UpdateCandidate from "@/components/Candidate/dialogs/UpdateCandidate.vue";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import { useDashboardStore } from "@/store/DashboardStore/Dashboard";
import { getMonthDateYearTimeFormat } from "@/composables/DateTime";
import searchIcon from "@/assets/icons/search.png";
import { inject, ref, watch } from "vue";
import { useCandidateStore } from "@/store/Candidate/CandidateStore";
import male from "@/assets/icons/businessman.png";
import female from "@/assets/icons/woman.png";
import { useJobApplicationsStore } from "@/store/JobApplications/JobApplicationsStore";
const jobApplicationsStore = useJobApplicationsStore();
const dashboardStore = useDashboardStore();
const candidateStore = useCandidateStore();
const tabs = ref(0);
const show = ref([]);
const searchValue = ref(null);
const refForm = ref();
const $cookies = inject("$cookies");
const user = $cookies.get("user");
const searchCandidate = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      candidateStore.searchCandidate(searchValue.value);
    }
  });
};
const viewJobApplication = (id) => {
  if (
    user.role === "SUPER_USER" ||
    user.role === "SUPER_ADMIN" ||
    user.role === "ADMIN"
  ) {
    jobApplicationsStore.viewJobApplication(id);
  }
};
watch(
  () => [tabs.value, dashboardStore.searchDialog],
  () => {
    searchValue.value = null;
    if (!dashboardStore.searchDialog) {
      candidateStore.searchedCandidates = [];
    }
  }
);
watch(
  () => searchValue.value,
  () => {
    if (!searchValue.value) {
      candidateStore.searchedCandidates = [];
    }
  }
);
</script>

<style scoped>
.v-tab--selected .v-tab__slider {
  height: 1px;
}
.v-tabs.v-tabs-pill .v-tab.v-btn:hover {
  color: #b6d230;
}
.v-tabs.v-tabs-pill .v-slide-group-item--active.v-tab--selected {
  background-color: #b6d230 !important;
  border-radius: 10px;
  color: rgb(43, 43, 45) !important;
}
:deep(.v-list-item) {
  min-height: none !important;
}
</style>
