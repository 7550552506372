import api from "@/services/api";
import { defineStore } from "pinia";
import VueCookies from "vue-cookies";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    isLoggedIn: false,
    isLoading: false,
    user: {},
    token: "",
    fcmToken: null,
    permission: "start",
    snackbar: false,
    notificationAlert: false,
    locationAlert: false,
    isLocation: true,
    onLine: navigator.onLine,
    noWifiSnackbar: !navigator.onLine,
  }),
  actions: {
    async logout() {
      VueCookies.remove("token");
      VueCookies.remove("user");
      VueCookies.remove("refresh_token");
      this.isLoading = false;
      localStorage.removeItem("authStore");
      this.router.push("/login");
    },
    async login(reqData) {
      if (this.permission === "granted") {
        this.isLoading = true;
        await api
          .post("/auth/login", {
            username: reqData.username,
            password: reqData.password,
            fcmToken: this.fcmToken,
          })
          .then(async (v) => {
            const userResponse = v.data;
            VueCookies.set("token", userResponse.token);
            VueCookies.set("user", userResponse.user);
            VueCookies.set("refresh_token", userResponse.refresh_token);
            if (reqData.rememberMe) {
              VueCookies.set("rememberMe", {
                username: reqData.username,
                password: reqData.password,
                rememberMe: true,
              });
            } else {
              VueCookies.remove("rememberMe");
            }
            const userId = userResponse.user.id;
            await api
              .get(`/users/${userId}`, {})
              .then((v) => {
                const userResponse = {
                  active: v.data.active,
                  bloodGroup: v.data.bloodGroup,
                  department: v.data.department,
                  designation: v.data.designation,
                  dateOfJoining: v.data.dateOfJoining,
                  username: v.data.username,
                  code: v.data.code,
                  name: v.data?.name,
                  gender: v.data.gender,
                  id: v.data.id,
                  teamId: v.data.teamId,
                  number: v.data.number,
                  online: v.data.online,
                  permissions: v.data.permissions,
                  role: v.data.role,
                  workLocation: v.data.workLocation,
                };
                VueCookies.set("user", userResponse);

                this.router.go("/");
                this.isLoading = false;
              })
              .catch((err) => {
                this.isLoading = false;
                return err;
              });
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      } else {
        this.requestPermission();
      }
    },
    requestPermission() {
      console.log("Requesting permission...");
      this.snackbar = true;
      Notification.requestPermission().then((permission) => {
        console.log(permission);
        if (permission === "granted") {
          console.log("Notification permission granted.");
          this.snackbar = false;

          this.permission = permission;
        } else if (permission === "denied") {
          console.warn("Notification permission denied");
          this.permission = permission;
          this.snackbar = true;
          window.reload();
          this.showAllowNotificationPopup();
        } else if (permission === "default") {
          console.warn("Notification permission deafult");
          this.permission = permission;
          this.snackbar = true;
          this.showAllowNotificationPopup();
        }
      });
    },
    showAllowNotificationPopup() {
      if (confirm("Enable notifications to receive updates and alerts?")) {
        this.requestPermission();
      } else {
        console.log("User chose not to enable notifications");
      }
    },
    location() {
      if ("geolocation" in navigator) {
        this.isLocation = true;
        this.locationAlert = false;
      } else {
        this.isLocation = false;
        this.locationAlert = true;
      }
    },
    async syncUser(userId) {
      this.isLoading = true;
      await api
        .get(`/users/${userId}`)
        .then((res) => {
          const userResponse = { ...res.data };
          if (!userResponse.active) {
            this.router.push({ name: "Login" });
          }
          VueCookies.set("user", userResponse);
          this.user = userResponse;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
      if (this.onLine) {
        this.noWifiSnackbar == true;
        setTimeout(() => {
          this.noWifiSnackbar = false;
        }, 2000);
      } else this.noWifiSnackbar = true;
    },
  },
  persist: true,
});
