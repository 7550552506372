<template>
  <v-dialog v-model="dialog" activator="parent" width="350">
    <v-card color="#303030" rounded="lg" max-height="400">
      <v-card-text class="pa-3 pb-0">
        <h3 class="font-weight-regular text-center mb-4">Drop Job Application</h3>
        <DialogCloseBtn @close-dialog="dialog = false" />

        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12">
              <CustomSelectComponent
                label="Type"
                v-model="selectedStatus"
                :items="dropStatus"
                :rules="[requiredValidator]"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                variant="outlined"
                color="#b2d250"
                @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                label="Remark"
                :rules="[requiredValidator, commentLengthValidator]"
                v-model="comment"
                counter="30"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn variant="tonal" color="#74a942" @click="onSubmit()">Submit </v-btn>
        <v-btn variant="tonal" color="red" class="me-2" @click="closeDialog()"
          >Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onConfirm()"
    @update-model="confirmDialogStage = false"
    title="Drop Job Application"
    text="Are you sure you want to drop job application?"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import { ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { commentLengthValidator } from "@/composables/Validators";
const jobApplicationStore = useJobApplicationStore();
const dropStatus = ["Not Interested", "Not Reachable"];
const confirmDialogStage = ref(false);
const comment = ref("");
const dialog = ref(false);
const selectedStatus = ref("");
const refForm = ref();
// const interviewStore = useInterviewStore();

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const onConfirm = () => {
  let data = {
    type: selectedStatus.value,
    comment: comment.value,
  };
  confirmDialogStage.value = false;
  closeDialog();
  jobApplicationStore.dropJobApplication(data);
};
const closeDialog = () => {
  dialog.value = false;
};
</script>
