import api from "@/services/api";
import { defineStore } from "pinia";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import VueCookies from "vue-cookies";
import { useOverviewStore } from "../Overview/OverviewStore";

export const useJobApplicationsStore = defineStore("jobApplicationsStore", {
  state: () => ({
    overlay: false,
    filterDrawer: false,
    overviewStore: useOverviewStore(),
    jobApplicationStore: useJobApplicationStore(),
    userId: VueCookies.get("user")?.id,
    month: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
    isLoading: false,
    jobApplications: [],
    filteredData: [],
    clientName: "",
    jobName: "",
    jobStatus: "",
    joiningStatus: "",
    selectedApplicationId: "",
    seletedStage: "",
    search: "",
    selectedStatus: "",
  }),
  actions: {
    async getJobApplicationsByDateRange() {
      let userId = this.overviewStore.selectedMemberId
        ? this.overviewStore.selectedMemberId
        : this.userId;
      (this.isLoading = true),
        await api
          .get(
            `/job-applications/user/${userId}?year=${this.month.year}&month=${
              this.month.month + 1
            }`
          )
          .then((res) => {
            this.jobApplications = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },

    async viewJobApplication(applicationId) {
      await this.jobApplicationStore.getJobApplication(applicationId);
      this.overlay = true;
    },
    refreshJobApplications() {
      this.getJobApplicationsByDateRange();
    },
    applyFilter() {
      const filteredApplications = this.jobApplications.filter(
        (application) => {
          const clientNameMatch =
            !this.clientName || application.clientName === this.clientName;
          const jobNameMatch =
            !this.jobName || application.jobTitle === this.jobName;
          const jobStatusMatch =
            !this.jobStatus ||
            application.jobStatus
              .toLocaleLowerCase()
              .includes(this.jobStatus.toLocaleLowerCase());
          const joiningStatusMatch =
            !this.joiningStatus.toLocaleLowerCase() ||
            application.noticePeriodStatus.toLocaleLowerCase() ===
              this.joiningStatus.toLocaleLowerCase();

          return (
            clientNameMatch &&
            jobNameMatch &&
            jobStatusMatch &&
            joiningStatusMatch
          );
        }
      );
      this.filteredData = filteredApplications;
      this.filterDrawer = false;
    },
    setFilteredData(filteredData) {
      this.filteredData = filteredData;
    },
    clearFilter() {
      (this.clientName = ""),
        (this.jobName = ""),
        (this.jobStatus = ""),
        (this.joiningStatus = "");
      this.filteredData = null;
    },
    selecteCard(stage) {
      this.seletedStage = stage;
      if (this.selectedStatus || this.search) {
        this.selectedStatus = "";
        this.search = "";
      }
    },
    onDateRangeChanged(from, to) {
      this.range = { start: from, end: to };
      this.getJobApplicationsByDateRange();
    },

    applicationStatus(applicationStatus, stage, status) {
      const formattedApplicationStatus = applicationStatus.toLocaleLowerCase();
      const formattedStage = stage.toLocaleLowerCase();
      const formattedStatus = status.toLocaleLowerCase();
      const statusByStage = {
        submission: {
          initiated: "Submitted",
          submitted: "Submitted",
          lapsed: "System Reject",
          senior: "Senior Reject",
          "senior approved": "Approved",
          "system rejected": "System Rejected",
        },
        screening: {
          initiated: "Approved",
          "shared with client": "Screening Feedback Pending",
          shortlisted: "Shortlisted",
          rejected: "Screening Rejected",
        },
        "level 1": {
          "interview scheduled": "L1 Scheduled",
          "interview rescheduled": "L1 Rescheduled",
          "interview completed": "L1 Done",
          "interview passed": "L1 Passed",
          "interview cancelled": "L1 withdrawn",
          "interview failed": "L1 Reject",
        },
        "level 2": {
          "interview scheduled": "L2 Scheduled",
          "interview rescheduled": "L2 Rescheduled",
          "interview completed": "L2 Done",
          "interview passed": "L2 Passed",
          "interview cancelled": "L2 withdrawn",
          "interview failed": "L2 Reject",
        },
        "level 3": {
          "interview scheduled": "L3 Scheduled",
          "interview rescheduled": "L3 Rescheduled",
          "interview completed": "L3 Done",
          "interview passed": "L3 Passed",
          "interview cancelled": "L3 withdrawn",
          "interview failed": "L3 Reject",
        },
        selection: {
          selected: "Selected",
          "offer received": "Offered",
          "offer accepted": "Offered",
          "offer rejected": "Offer Rejected",
          "placement initiated": "Placement",
          "placement completed": "Placement",
          "placement on hold": "Placement Hold",
          "placement withdrawn": "Placement withdrawn",
          "placement rejected": "Placement Rejected",
        },
        joined: {
          "placement completed": "Joined",
        },
      };
      const statusByCommon = {
        hold: status,
        withdrawn: status,
        rejected: status,
      };
      return (
        statusByStage[formattedStage]?.[formattedStatus] ??
        statusByCommon[formattedApplicationStatus] ??
        formattedStatus
      );
    },
    applicationStatusColor(applicationStatus, stage, status) {
      const statusData = this.applicationStatus(
        applicationStatus,
        stage,
        status
      )?.toLocaleLowerCase();
      const statusMap = {
        approved: "green",
        complete: "green",
        passed: "green",
        shortlist: "green",
        selected: "green",
        joined: "green",
        pending: "orange",
        scheduled: "orange",
        submitted: "orange",
        reject: "red",
        lapsed: "red",
        offered: "blue",
        done: "blue",
        placement: "blue",
      };
      const statusByCommon = {
        withdrawn: "cyan-lighten-3",
        hold: "yellow",
        rejected: "red",
      };

      return Object.keys(statusByCommon).find((key) =>
        applicationStatus.toLocaleLowerCase().includes(key)
      )
        ? statusByCommon[
            Object.keys(statusByCommon).find((key) =>
              applicationStatus.toLocaleLowerCase().includes(key)
            )
          ]
        : statusMap[
            Object.keys(statusMap).find((key) => statusData.includes(key))
          ];
    },
  },
  getters: {
    getFormattedJobApplication() {
      const dataToUse =
        this.filteredData?.length > 0
          ? this.filteredData
          : this.jobApplications;

      if (this.search) {
        return dataToUse.filter((data) => {
          const { candidateName, clientName, jobTitle, seniorName } = data;
          return [candidateName, clientName, jobTitle, seniorName].some(
            (name) => name.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      if (this.selectedStatus) {
        const data = dataToUse?.filter((data) => {
          return (
            this.applicationStatus(
              data.status,
              data.currentHiringStage,
              data.currentHiringStatus
            ) == this.selectedStatus
          );
        });
        return data;
      }
      if (this.seletedStage) {
        switch (this.seletedStage) {
          case "Submitted":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("submitted")
            );
          case "Approved":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("approved")
            );
          case "In Progress":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("in progress")
            );
          case "Hold":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("on hold")
            );
          case "Rejected":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("rejected")
            );
          case "Withdrawn":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("withdrawn")
            );
          case "Completed":
            return dataToUse.filter((data) =>
              data.status.toLocaleLowerCase().includes("completed")
            );
          case "All":
            return dataToUse;
          default:
        }
      }

      return dataToUse.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
    },
    getHiringStatus() {
      const dataToUse =
        this.filteredData?.length > 0
          ? this.filteredData
          : this.jobApplications;

      const statusFilters = {
        Submitted: "submitted",
        Approved: "approved",
        "In Progress": "in progress",
        Hold: "on hold",
        Rejected: "rejected",
        Withdrawn: "withdrawn",
        All: null,
      };
      const selectedFilter = this.seletedStage
        ? statusFilters[this.seletedStage]
        : null;

      let filteredData = dataToUse;

      if (selectedFilter !== null && this.selectedRecruiter) {
        filteredData = dataToUse.filter(
          (data) =>
            data.status.toLowerCase().includes(selectedFilter) &&
            data.recruiterName == this.selectedRecruiter
        );
      } else if (selectedFilter !== null) {
        filteredData = dataToUse.filter((data) =>
          data.status.toLowerCase().includes(selectedFilter)
        );
      }

      const statusData = filteredData.map((data) =>
        this.applicationStatus(
          data.status,
          data.currentHiringStage,
          data.currentHiringStatus
        )
      );

      const statusCounts = {};
      statusData.forEach((status) => {
        if (statusCounts[status]) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      });
      const formattedStatuses = Object.keys(statusCounts).map(
        (formattedName) => ({
          name: formattedName,
          count: statusCounts[formattedName],
        })
      );
      return formattedStatuses;
    },
    submittedCount() {
      const submitted = this.jobApplications?.filter((application) =>
        application.status.toLocaleLowerCase().includes("submitted")
      );
      return submitted.length;
    },
    approvedCount() {
      const approvedApplications = this.jobApplications?.filter((application) =>
        application.status.toLocaleLowerCase().includes("approved")
      );
      return approvedApplications.length;
    },
    inProgressCount() {
      const inProgressApplications = this.jobApplications?.filter(
        (application) =>
          application.status.toLocaleLowerCase().includes("in progress")
      );
      return inProgressApplications.length;
    },
    OnHoldCount() {
      const onHoldApplications = this.jobApplications?.filter((application) =>
        application.status.toLocaleLowerCase().includes("on hold")
      );
      return onHoldApplications.length;
    },
    rejectedCount() {
      const rejectedApplications = this.jobApplications?.filter((application) =>
        application.status.toLocaleLowerCase().includes("rejected")
      );
      return rejectedApplications.length;
    },
    withdrawnCount() {
      const withdrawnApplications = this.jobApplications?.filter(
        (application) =>
          application.status.toLocaleLowerCase().includes("withdrawn")
      );
      return withdrawnApplications.length;
    },
    completedCount() {
      const completedApplications = this.jobApplications?.filter(
        (application) =>
          application.status.toLocaleLowerCase().includes("completed")
      );
      return completedApplications.length;
    },
    getAllClient() {
      const clients = this.jobApplications.map(
        (application) => application.clientName
      );
      return [...new Set(clients)];
    },
    getAllJobs() {
      if (this.clientName) {
        const filteredJobs = this.jobApplications
          .filter((application) => application.clientName === this.clientName)
          .map((application) => application.jobTitle);

        return [...new Set(filteredJobs)];
      } else {
        const jobs = this.jobApplications.map(
          (application) => application.jobTitle
        );
        return [...new Set(jobs)];
      }
    },
  },
});
