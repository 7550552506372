// import api from "@/services/api";
import { addDataInLocalStorage } from "@/composables/LocalStorage";
import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
// import { toast } from "vue3-toastify";

export const useSettingStore = defineStore("settingStore", {
  state: () => ({
    departmentList: [],
    roleList: [],
    features: [],
    isLoading: false,
    holidays: [],
    isActive: true,
    officialMails: [],
    jobApplicationsStatus: [],
    activeJobApplicationsStatus: [],
  }),

  actions: {
    async addtDepartment(deapartment) {
      (this.isLoading = true),
        await api
          .post("/department", deapartment)
          .then((res) => {
            this.departmentList.push(res.data);
            addDataInLocalStorage("departments", this.departmentList);
            toast.success("Added Department", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async getRoles() {
      this.isLoading = true;
      await api
        .get("/roles")
        .then((res) => {
          this.roleList = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    refreshFeature() {
      this.getFeature();
    },
    async getFeature() {
      this.isLoading = true;
      await api
        .get("/features", {})
        .then((res) => {
          this.features = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getHolidays() {
      this.isLoading = true;
      await api
        .get(`/holidays/year/2024`)
        .then((res) => {
          this.holidays = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addHolidays(data) {
      this.isLoading = true;
      await api
        .post(`/holidays`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    // job applications common status
    async addStatus(statusData) {
      this.isLoading = true;
      api
        .post(`/job-application/statuses`, statusData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    refreshJobApplicationStatuses() {
      this.getJobApplicationsStatuses();
    },
    async getJobApplicationsStatuses() {
      this.isLoading = true;
      await api
        .get(`/job-application/statuses`)
        .then((res) => {
          this.jobApplicationsStatus = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getActiveJobApplicationsStatuses() {
      this.isLoading = true;
      await api
        .get(`/job-application/statuses/active`)
        .then((res) => {
          this.activeJobApplicationsStatus = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async activeJobApplicationsStatuses(statusId) {
      this.isLoading = true;
      await api
        .put(`/job-application/statuses/${statusId}/activate`)
        .then((res) => {
          if (res.status === 200) {
            const index = this.jobApplicationsStatus.findIndex(
              (status) => status.id === statusId
            );
            if (index != -1) {
              this.jobApplicationsStatus[index].active = true;
            }
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactiveJobApplicationsStatuses(statusId) {
      this.isLoading = true;
      await api
        .put(`/job-application/statuses/${statusId}/deactivate`)
        .then((res) => {
          if (res.status === 200) {
            const index = this.jobApplicationsStatus.findIndex(
              (status) => status.id === statusId
            );
            if (index != -1) {
              this.jobApplicationsStatus[index].active = false;
            }
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateJobApplicationsStatuses(statusId, statusData) {
      this.isLoading = true;
      await api
        .put(`/job-application/statuses/${statusId}`, statusData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    activateDeactivateStatus(status) {
      if (status?.active) {
        this.deactiveJobApplicationsStatuses(status?.id);
      } else {
        this.activeJobApplicationsStatuses(status?.id);
      }
    },

    //official mails
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.getOfficialMails();
    },
    async getOfficialMails() {
      this.isLoading = true;
      await api
        .get(this.getOfficialMailURL)
        .then((v) => {
          this.officialMails = v.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async assignEmail(emailId, userId) {
      this.isLoading = true;
      await api
        .put(`/official-email/${emailId}/assign/${userId}`)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async activeEmail(emailId) {
      this.isLoading = true;
      await api
        .put(`/official-email/${emailId}/activate`)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactiveEmail(emailId) {
      this.isLoading = true;
      await api
        .put(`/official-email/${emailId}/deactivate`)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },
  getters: {
    getOfficialMailURL() {
      if (this.isActive) {
        return "/official-email/active";
      } else {
        return "/official-email/in-active";
      }
    },
  },
});
