<template>
  <v-dialog v-model="dialog" activator="parent" :width="reason ? 700 : 400">
    <v-card color="#303030" class="pa-1">
      <DialogCloseBtn @close-dialog="dialog = false" />

      <v-card-text class="pa-3 pb-0">
        <h3 class="text-center font-weight-regular mb-3">Request to Rollback Status</h3>

        <v-row dense>
          <v-col :cols="reason ? 6 : 12">
            <v-form ref="refForm">
              <v-row no-gutters>
                <v-col cols="12">
                  <CustomSelectComponent
                    :rules="[requiredValidator]"
                    label="Reason"
                    :items="rollbackResons"
                    item-title="title"
                    item-value="index"
                    return-object
                    single-line
                    v-model="reason"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    variant="outlined"
                    color="#b2d250"
                    counter="30"
                    @input="description = $event.target.value.replace(/\s+/g, ' ')"
                    :rules="[requiredValidator, commentLengthValidator]"
                    label="Description"
                    v-model="description"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-card-actions class="justify-center gap-3">
              <v-btn variant="tonal" color="#b2d250" @click="onSubmit()">Submit</v-btn>
              <v-btn variant="tonal" color="red" @click="closeDialog()">Cancel </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="6" v-if="reason">
            <v-card color="transparent" border height="300">
              <div class="d-flex justify-end">
                <v-icon size="large" color="orange" class="ma-2 mb-0"
                  >mdi-alert-circle-outline</v-icon
                >
              </div>

              <p class="ma-4 mt-0">
                {{ getSelectedReason }}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onConfirm()"
    @update-model="confirmDialogStage = false"
    title="Rollback Status"
    :text="`Are you sure you want to Request to Rollback Status?`"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import { computed, ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { commentLengthValidator } from "@/composables/Validators";
const jobApplicationStore = useJobApplicationStore();

const confirmDialogStage = ref(false);
const dialog = ref(false);
const reason = ref();
const description = ref("");
const refForm = ref();

const getSelectedReason = computed(() => {
  return reason.value.description;
});
const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const onConfirm = () => {
  let data = {
    reason: reason.value.title,
    description: description.value,
  };
  confirmDialogStage.value = false;
  dialog.value = false;
  jobApplicationStore.requestRollback(data);
};
const closeDialog = () => {
  dialog.value = false;
};

const rollbackResons = [
  {
    title: "By Mistake",
    description:
      "If you have changed the application status by mistake and now wish to reopen it, please provide a brief description of what led to this change. This information will help us rectify the error and assist you in reopening your application promptly. Thank you for providing the necessary details.",
  },
  {
    title: "Hold",
    description:
      "It means you temporarily paused the process. Now, if you wish to reopen and continue with your application, please select this option. Reopening allows you to resume where you left off and proceed with your application journey.Please provide a detailed description of why you want to rollback or reopen your application.",
  },
  {
    title: "System Reject",
    description:
      "the application status is showing as 'System Reject', and you need to reopen it, please provide a detailed description explaining why you believe the rejection was a mistake. Your description will help us review and correct the issue promptly. Thank you for providing the details.",
  },
  {
    title: "Withdrawn",
    description:
      "If your application status shows as 'Withdrawn', please explain why you need to reopen it. Your description will help us address the issue and assist you promptly. Thank you for providing this information.",
  },
  {
    title: "Other",
    description:
      "If you selected 'Other' as the reason for your action, please provide more details or specify your reason in the space provided. This information will help us assist you effectively. Thank you for your cooperation.",
  },
];
</script>
