<template>
  <v-dialog v-model="dialog" activator="parent" max-width="450" persistent>
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />

      <v-card-text class="pa-4 pb-0">
        <h3 class="font-weight-regular text-center">{{ title }}</h3>

        <v-form ref="refForm">
          <v-textarea
            :placeholder="placeholder"
            rounded="lg"
            color="#b2d250"
            variant="outlined"
            counter="30"
            label="Reason"
            hide-details="auto"
            :rules="[requiredValidator, commentLengthValidator]"
            v-model="rejectReason"
            @input="rejectReason = $event.target.value.replace(/\s+/g, ' ')"
            class="mt-3"
          />
        </v-form>

        <v-card-actions class="d-flex justify-center flex-wrap gap-3">
          <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
          <v-btn @click="dialog = false" variant="tonal" color="red"> Cancel </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>

  <ConfirmationDialoge
    v-model="confirmDialog"
    @action-yes="onWithdrawn()"
    @update-model="confirmDialog = false"
    :title="`${title} Placement`"
    :text="`Are you sure you want to ${title} Placement?`"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { ref, defineProps, defineEmits } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { commentLengthValidator } from "@/composables/Validators";
defineProps(["title", "placeholder"]);
const emits = defineEmits(["submit"]);
const dialog = ref(false);
const confirmDialog = ref(false);
const rejectReason = ref("");
const refForm = ref();
const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialog.value = true;
    }
  });
};
const onWithdrawn = () => {
  emits("submit", rejectReason.value);
  confirmDialog.value = false;
  dialog.value = false;
};
</script>
