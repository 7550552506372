import api from "@/services/api";
export const addDataInLocalStorage = (name, data) => {
  localStorage.setItem(`${name}`, JSON.stringify(data));
};
export const getDataFromLocalStorage = (name) => {
  const data = localStorage.getItem(`${name}`);
  return JSON.parse(data);
};
export const getRoles = async () => {
  await api
    .get("/roles")
    .then(async (res) => {
      addDataInLocalStorage("roles", res.data);
    })
    .catch((err) => {
      return err;
    });
};
export const getRoleName = async () => {
  await api
    .get("/roles/names")
    .then(async (res) => {
      addDataInLocalStorage("rolesName", res.data);
      return res.data
    })
    .catch((err) => {
      return err;
    });
};
export const getDepartments = async () => {
  await api
    .get("/department")
    .then((res) => {
      addDataInLocalStorage("departments", res.data);
    })
    .catch((err) => {
      return err;
    });
};
export const getDesignations = async () => {
  await api
    .get("/designation")
    .then((res) => {
      addDataInLocalStorage("designations", res.data);
    })
    .catch((err) => {
      return err;
    });
};
