import axios from "axios";
import VueCookies from "vue-cookies";
import { toast } from "vue3-toastify";

// const baseURLDev = "http://192.168.2.238:8080/api";
const baseURLProd = "https://genxhire-recruitment-api.azurewebsites.net/api"; //"https://api.recruitments.genxhire.in/api"

const api = axios.create({
  baseURL: baseURLProd,
  timeout: 1000 * 20,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

api.interceptors.request.use(
  async (config) => {
    const token = VueCookies.get("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return response;
    } else {
      return response;
    }
  },
  (err) => {
    if (err.response && err.response.data) {
      if (err.response.status === 401 || err.response.status === 403) {
        handleValidationError(err.response.data.detail);
        const currentPath = window.location.pathname;
        if (currentPath.includes("login")) {
          VueCookies.remove("token");
          VueCookies.remove("user");
          VueCookies.remove("refresh_token");
          localStorage.removeItem("authStore");
        } else {
          handleUnauthorizedError();
        }
      } else {
        handleValidationError(err.response.data.detail);
      }
    } else if (err.code === "ECONNABORTED") {
      handleTimeoutError();
    } else {
      return Promise.reject(err);
    }
  }
);

function handleUnauthorizedError() {
  VueCookies.remove("token");
  VueCookies.remove("user");
  VueCookies.remove("refresh_token");
  localStorage.removeItem("authStore");
  window.location.href = "/login";
}

function handleValidationError(detail) {
  toast.error(detail, {
    autoClose: 1500,
    theme: "dark",
    position: toast.POSITION.TOP_CENTER,
    transition: toast.TRANSITIONS.ZOOM,
  });
}

function handleTimeoutError() {
  toast.error("Timeout!!. Please try again later.", {
    autoClose: 1500,
    theme: "dark",
    position: toast.POSITION.TOP_CENTER,
    transition: toast.TRANSITIONS.ZOOM,
  });
}

export default api;
