<template>
  <v-card
    class="detailsPage"
    transition="scroll-y-reverse-transition"
    theme="dark"
    color="#343435"
    v-if="!jobApplicationStore.isLoading"
  >
    <v-card-text v-if="jobApplicationStore.jobApplication" class="pa-2">
      <v-row dense>
        <v-col cols="12" lg="7" md="6" sm="6">
          <div>
            <div class="d-flex justify-space-between">
              <div class="d-flex">
                <div class="me-3 align-self-center">
                  <v-avatar color="#b2d250" variant="tonal" size="48"
                    ><h3 class="font-weight-regular">
                      {{ initials(jobApplicationStore.jobApplication.candidate.name) }}
                    </h3>
                  </v-avatar>
                </div>
                <div>
                  <div class="d-flex">
                    <p
                      class="font-weight-regular align-self-center me-5"
                      style="cursor: pointer"
                      @click="
                        copyToClipboard(
                          `${jobApplicationStore.jobApplication.candidate.name}`
                        )
                      "
                    >
                      {{ jobApplicationStore.jobApplication.candidate.name }}
                    </p>
                    <h4 class="font-weight-regular align-self-center me-5">
                      <v-icon size="x-small" class="text-grey-lighten-2"
                        >mdi-map-marker-outline</v-icon
                      >
                      {{
                        jobApplicationStore.jobApplication.currentLocation
                          ? jobApplicationStore.jobApplication.currentLocation
                          : "N/A"
                      }}
                    </h4>
                    <v-chip
                      rounded="lg"
                      size="small"
                      class="me-3"
                      :color="
                        getJoiningStatusColor(jobApplicationStore.jobApplication.status)
                      "
                    >
                      <h4 class="font-weight-regular">
                        {{ jobApplicationStore.jobApplication.status }}
                      </h4></v-chip
                    >
                    <h4 class="font-weight-regular me-2 align-self-center">
                      {{
                        jobApplicationStore.jobApplication.noticePeriodStatus
                          .toLowerCase()
                          .includes("serving")
                          ? `${jobApplicationStore.jobApplication.noticePeriodDays} Days`
                          : getCapitalizeText(
                              jobApplicationStore.jobApplication.noticePeriodStatus
                                ?.split("_")
                                .join(" ")
                            )
                      }}
                    </h4>
                    <div class="d-flex align-center">
                      <div class="me-4">
                        <v-btn
                          variant="tonal"
                          :color="
                            jobApplicationStore.jobApplication.resumeUpdated
                              ? '#b2d250'
                              : 'red'
                          "
                          rounded="lg"
                          size="small"
                          @click="jobApplicationStore.viewOrUpdateResumeDialog()"
                        >
                          <v-icon icon="mdi-file-account-outline"> </v-icon>
                          <v-tooltip
                            activator="parent"
                            location="bottom"
                            text="Resume"
                          ></v-tooltip>
                        </v-btn>
                      </div>
                      <div>
                        <v-btn
                          @click="jobApplicationStore.viewOrUpdateAcknowledgementDialog()"
                          variant="tonal"
                          rounded="lg"
                          size="small"
                          :color="
                            jobApplicationStore.jobApplication.acknowledged
                              ? '#b2d250'
                              : 'red'
                          "
                        >
                          <v-icon icon="mdi-handshake-outline"></v-icon>
                          <v-tooltip
                            activator="parent"
                            text="Acknowledgement"
                            location="bottom"
                          ></v-tooltip>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-space-between">
                    <div class="d-flex">
                      <h4 class="font-weight-regular text-grey-lighten-2 me-2">
                        {{
                          jobApplicationStore.jobApplication.currentDesignation
                            ? jobApplicationStore.jobApplication.currentDesignation
                            : "N/A"
                        }}
                      </h4>
                      <h4 class="font-weight-regular text-grey-lighten-2">
                        {{
                          jobApplicationStore.jobApplication.currentCompany
                            ? jobApplicationStore.jobApplication.currentCompany
                            : "N/A"
                        }}
                      </h4>
                    </div>
                    <div>
                      <v-btn
                        size="x-small"
                        color="blue"
                        icon="mdi-pencil"
                        variant="tonal"
                        class="mt-1"
                        v-if="
                          user.role.includes('SUPER_ADMIN') ||
                          user.role.includes('SUPER_USER')
                        "
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <UpdateJobApplication />
                      </v-btn>
                    </div>
                  </div>

                  <div class="d-flex justify-space-between">
                    <div class="d-flex text-grey-lighten-1">
                      <h4
                        class="font-weight-regular me-1"
                        style="cursor: pointer"
                        @click="
                          copyToClipboard(
                            jobApplicationStore.jobApplication.candidate.phoneNumbers[0]
                              .number
                          )
                        "
                      >
                        +91
                        {{
                          jobApplicationStore.jobApplication.candidate.phoneNumbers[0]
                            ? jobApplicationStore.jobApplication.candidate.phoneNumbers[0]
                                .number
                            : "N/A"
                        }}
                      </h4>
                      <v-divider vertical class="me-1"></v-divider>
                      <h4
                        class="font-weight-regular"
                        style="cursor: pointer"
                        @click="
                          copyToClipboard(
                            jobApplicationStore.jobApplication.candidate.emails[0].email
                          )
                        "
                      >
                        {{
                          jobApplicationStore.jobApplication.candidate.emails[0]
                            ? jobApplicationStore.jobApplication.candidate.emails[0].email
                            : "N/A"
                        }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <v-btn icon="mdi-dots-vertical" size="x-small" variant="tonal">
                  <v-icon>mdi-dots-vertical</v-icon>
                  <v-tooltip activator="parent" text="More" location="bottom"></v-tooltip>
                  <v-menu activator="parent" location="right">
                    <v-list>
                      <v-hover v-slot="{ isHovering, props }">
                        <v-list-item class="user-list-name" v-bind="props">
                          <v-chip
                            :variant="isHovering ? 'tonal' : 'text'"
                            class="w-100 rounded"
                            size="large"
                            :style="isHovering ? `cursor: pointer` : ''"
                            @click="jobApplicationStore.updateJobApplicatioDialog = true"
                          >
                            <div class="d-flex">
                              <v-icon
                                class="align-self-center me-2"
                                size="small"
                                color="green"
                                >mdi-pencil</v-icon
                              >
                              <p
                                class="text-button text-capitalize"
                                block
                                flat
                                variant="text"
                              >
                                Update
                              </p>
                            </div>
                          </v-chip>
                        </v-list-item>
                      </v-hover>
                      <v-hover v-slot="{ isHovering, props }">
                        <v-list-item class="user-list-name" v-bind="props">
                          <v-chip
                            :variant="isHovering ? 'tonal' : 'text'"
                            class="w-100 rounded"
                            size="large"
                            :style="isHovering ? `cursor: pointer` : ''"
                            @click="
                              onChat(
                                jobApplicationStore.jobApplication.candidate.phoneNumbers
                              )
                            "
                          >
                            <div class="d-flex">
                              <v-icon
                                class="align-self-center me-2"
                                size="small"
                                color="green"
                                >mdi-whatsapp</v-icon
                              >
                              <p
                                class="text-button text-capitalize"
                                block
                                flat
                                variant="text"
                              >
                                WhatsApp
                              </p>
                            </div>
                          </v-chip>
                        </v-list-item>
                      </v-hover>
                      <v-hover v-slot="{ isHovering, props }">
                        <v-list-item class="user-list-name" v-bind="props">
                          <v-chip
                            :variant="isHovering ? 'tonal' : 'text'"
                            class="w-100 rounded"
                            size="large"
                            :style="isHovering ? `cursor: pointer` : ''"
                            @click="
                              onMail(jobApplicationStore.jobApplication.candidate.emails)
                            "
                          >
                            <div class="d-flex">
                              <v-icon
                                class="align-self-center me-2"
                                size="small"
                                color="yellow"
                                >mdi-gmail</v-icon
                              >
                              <p
                                class="text-button text-capitalize"
                                block
                                flat
                                variant="text"
                              >
                                Mail
                              </p>
                            </div>
                          </v-chip>
                        </v-list-item>
                      </v-hover>
                    </v-list>
                  </v-menu>
                </v-btn>
              </div>
            </div>

            <div class="d-flex ma-2 mt-5 justify-space-between">
              <div>
                <h4 class="font-weight-regular align-self-center">
                  Current CTC: <span>&#8377;</span>
                  {{
                    jobApplicationStore.jobApplication.currentSalary
                      ? jobApplicationStore.jobApplication.currentSalary?.toLocaleString(
                          "en-IN"
                        )
                      : "N/A"
                  }}
                </h4>
                <h4 class="font-weight-regular align-self-center">
                  Expected CTC : <span>&#8377;</span>
                  {{
                    jobApplicationStore.jobApplication.expectedSalary
                      ? jobApplicationStore.jobApplication.expectedSalary?.toLocaleString(
                          "en-IN"
                        )
                      : "N/A"
                  }}
                </h4>
              </div>

              <div class="text-end align-self-end">
                <h4 class="font-weight-regular align-self-center">
                  Last Working Date :
                  {{
                    jobApplicationStore.jobApplication.lastWorkingDate
                      ? jobApplicationStore.jobApplication.lastWorkingDate
                      : "N/A"
                  }}
                </h4>
                <h4 class="font-weight-regular align-self-center">
                  Relevant Exp :
                  {{
                    jobApplicationStore.jobApplication.relevantExperience
                      ? `${jobApplicationStore.jobApplication.relevantExperience} Years`
                      : "N/A"
                  }}
                </h4>
                <h4 class="font-weight-regular align-self-center">
                  Total Exp :
                  {{
                    jobApplicationStore.jobApplication.totalExperience
                      ? `${jobApplicationStore.jobApplication.totalExperience} Years`
                      : "N/A"
                  }}
                </h4>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="5" md="6" sm="6">
          <div>
            <div class="d-flex justify-space-between">
              <div class="d-flex">
                <h3
                  class="font-weight-regular me-2"
                  style="cursor: pointer"
                  @click="copyToClipboard(jobApplicationStore.jobApplication?.job?.title)"
                >
                  {{ jobApplicationStore.jobApplication.job.title }}
                </h3>
                <h5 class="font-weight-regular text-grey-lighten-2">
                  <v-chip
                    size="small"
                    class="text-capitalize"
                    rounded="lg"
                    :color="
                      getJobStatusColor(jobApplicationStore.jobApplication.job.status)
                    "
                  >
                    {{ jobApplicationStore.jobApplication.job.status }}</v-chip
                  >
                </h5>
              </div>
              <div>
                <h5 class="font-weight-regular text-grey-lighten-2">
                  <v-chip color="#b2d250" rounded="lg">
                    <span>&#8377;</span>
                    {{
                      jobApplicationStore.jobApplication.job.salary?.toLocaleString(
                        "en-IN"
                      )
                    }}
                  </v-chip>
                </h5>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="w-100">
                <div class="d-flex justify-space-between pt-1">
                  <h4
                    class="font-weight-regular text-grey-lighten-2"
                    style="cursor: pointer"
                    @click="
                      copyToClipboard(
                        jobApplicationStore.jobApplication?.job.client?.legalName
                      )
                    "
                  >
                    {{ jobApplicationStore.jobApplication.job.client.legalName }}
                  </h4>
                  <v-btn
                    size="small"
                    elevation="8"
                    color="teal"
                    v-if="jobApplicationStore.jobApplication.job.jobDescriptionUrl"
                    @click="
                      jobStore.viewJD(
                        jobApplicationStore.jobApplication.job.jobDescriptionUrl
                      )
                    "
                    >View JD</v-btn
                  >
                </div>
                <h5 class="font-weight-regular pt-1 text-grey-lighten-2">
                  <v-chip size="small" rounded="lg" color="orange">
                    {{ jobApplicationStore.jobApplication.job.jobCategory }}</v-chip
                  >
                </h5>
              </div>

              <div
                class="w-25"
                v-if="!jobApplicationStore.jobApplication.job.jobDescriptionUrl"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <v-img :src="emptyBox" :width="60" :height="60" />
                  </div>
                  <h3 class="align-self-center font-weight-regular">No JD</h3>
                </div>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <h4
                  class="font-weight-regular pt-2"
                  style="cursor: pointer"
                  @click="
                    copyToClipboard(
                      jobApplicationStore.jobApplication?.hiringManger?.name
                    )
                  "
                >
                  Hiring Manager :
                  {{
                    jobApplicationStore.jobApplication?.hiringManger
                      ? `${jobApplicationStore.jobApplication?.hiringManger?.name}`
                      : "N/A"
                  }}
                </h4>
                <h4 class="font-weight-regular pt-2">
                  Min Experience :
                  {{
                    jobApplicationStore.jobApplication.job.minExperience
                      ? `${jobApplicationStore.jobApplication.job.minExperience} Years`
                      : "N/A"
                  }}
                </h4>
                <h4 class="font-weight-regular pt-1">
                  Max Experience :
                  {{
                    jobApplicationStore.jobApplication.job.maxExperience
                      ? `${jobApplicationStore.jobApplication.job.maxExperience} Years`
                      : "N/A"
                  }}
                </h4>
              </div>
              <div>
                <h4 class="font-weight-regular pt-1">
                  Notice Period :
                  {{
                    jobApplicationStore.jobApplication.job.noticePeriod
                      ? `${jobApplicationStore.jobApplication.job.noticePeriod} Days`
                      : "N/A"
                  }}
                </h4>
                <h4 class="font-weight-regular pt-2">
                  Openings :
                  {{
                    jobApplicationStore.jobApplication.job.openings
                      ? jobApplicationStore.jobApplication.job.openings
                      : "N/A"
                  }}
                </h4>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <h3 class="font-weight-regular text-blue me-3">
            Submitted By :
            {{ jobApplicationStore.jobApplication.recruiter.name }}
          </h3>
          <v-btn color="red" size="small" v-if="jobApplicationStore.showDropBtn()"
            >Drop
            <DropJobApplicationDialog />
          </v-btn>
        </div>
        <h3 class="font-weight-regular text-orange">
          Submitted to :
          {{ jobApplicationStore.jobApplication.senior.name }}
        </h3>
      </div>
    </v-card-text>

    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col cols="12" lg="9" md="9" sm="9">
        <HiringStages />
      </v-col>

      <v-col cols="12" lg="3" md="3" sm="3">
        <JobApplicationHistory />
      </v-col>
    </v-row>
  </v-card>

  <ViewUpadteCVDialog />
  <ViewUpadteAcknowledgeDialog />
  <UpdateAcknowledgement />
  <UpdateJobApplicationDetails />
</template>

<script setup>
import emptyBox from "@/assets/icons/empty-box.png";
import UpdateJobApplicationDetails from "@/components/JobAplication/dialogs/UpdateJobApplication.vue";
import JobApplicationHistory from "@/components/JobAplication/components/JobApplicationHistory.vue";
import UpdateJobApplication from "@/components/JobAplication/components/UpdateJobApplication.vue";
import ViewUpadteCVDialog from "@/components/JobAplication/components/ViewUpadteCVDialog.vue";
import ViewUpadteAcknowledgeDialog from "@/components/JobAplication/components/ViewUpadteAcknowledgeDialog.vue";
import DropJobApplicationDialog from "@/components/JobAplication/dialogs/DropJobApplicationDialog.vue";
import { copyToClipboard } from "@/composables/Formatters";
import HiringStages from "@/components/JobAplication/components/HiringStages.vue";
import UpdateAcknowledgement from "@/components/JobAplication/dialogs/UpdateAcknowledgement.vue";
import { initials } from "@/composables/Formatters";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { getCapitalizeText } from "@/composables/Formatters";
import { toast } from "vue3-toastify";
import { useJobStore } from "@/store/Job/JobStore";
import { inject } from "vue";
const $cookies = inject("$cookies");
const user = $cookies.get("user");

const jobApplicationStore = useJobApplicationStore();
const jobStore = useJobStore();
const getJoiningStatusColor = (status) => {
  const formattedStatus = status.toLowerCase().split(" ").join("_");
  console.log(formattedStatus);
  const statusColor = {
    submitted: "orange",
    in_progress: "blue",
    on_hold: "yellow",
    approved: "green",
    withdrawn: "cyan-lighten-3",
    rejected: "red",
  };

  return Object.keys(statusColor).find((key) => formattedStatus.includes(key))
    ? statusColor[Object.keys(statusColor).find((key) => formattedStatus.includes(key))]
    : undefined;
};
const getJobStatusColor = (status) => {
  const formattedStatus = status.toLowerCase();
  switch (formattedStatus) {
    case "open":
      return "green";
    case "hold":
      return "orange";
    default:
      return "red";
  }
};
const onChat = (number, message) => {
  let msg = message ? message : "";
  if (number[0]) {
    window.open(`https://wa.me/91${number[0].number}?text=${encodeURIComponent(msg)}`);
  } else {
    toast.error("Number Not Found", {
      autoClose: 1500,
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      transition: toast.TRANSITIONS.ZOOM,
    });
  }
};
const onMail = (emails) => {
  if (emails[0]) {
    window.open(`mailto:${emails[0].email}?subject=`);
  } else {
    toast.error("Email Not Found", {
      autoClose: 1500,
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      transition: toast.TRANSITIONS.ZOOM,
    });
  }
};
</script>

<style scoped>
.detailsPage {
  height: 100vh;
  width: 175vh;
}

@media screen and (max-width: 1400px) {
  .detailsPage {
    width: 180vh;
  }
}
@media screen and (max-width: 1366px) {
  .detailsPage {
    width: 190vh;
  }
}
@media screen and (max-width: 1280px) {
  .detailsPage {
    max-width: 175vh;
  }
}
@media screen and (min-height: 900px) {
  .detailsPage {
    max-width: 150vh;
  }
}
@media screen and (max-width: 1152px) {
  .detailsPage {
    width: 160vh;
  }
}
</style>
